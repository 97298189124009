import React, { Component } from 'react';
import AppView1 from './Bubbles/AppView1.js'
import Testimonial from './Testimonial.js'
import InNews from './InNews.js'
import AppView2 from './Tabs/AppView2';
class AppContent extends Component{
  render(){
    return(
      <div>
        <AppView1/>
        <AppView2/>
        <InNews/>
        <Testimonial/>
      </div>
    );
  }
  
}

export default AppContent;