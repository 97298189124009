import React from 'react';
import SimList from '../Home/simList';
import '../Home/index.css'
import UsageComponent from '../Home/usage'
import { Grid } from '@material-ui/core';


export default class SimsClass extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showLowCashNotification : true,
            errorInUserDetails: null,
            errorInSims: null,
            selectedSimIndex: 0
        }
    }

    onSimClick = (i) => {
        this.setState({ selectedSimIndex: i })
      }
    
      onCloseNotificationClick = () => {
        this.setState({
          showLowCashNotification: false,
        })
      }

    render(){
        return(
      <div className='parent_container' style={{ marginTop: '5%' }}>
        {this.props.lowCashNotificationRaised && this.state.showLowCashNotification &&
          <div className="alert warning">
            <span className="closebtn" onClick={this.onCloseNotificationClick}>&times;</span>
            <strong>Warning!</strong> CellFish Balance is critically low. Load cash now to continue enjoying the seamless experience.
          </div>
        }
        <Grid container style={{ marginTop: '5%' }}>
          {this.props.sims && <Grid item md={5}>
            <SimList sims = {this.props.sims} onClick={this.onSimClick} />
          </Grid>}
          <Grid item md={5}>
            <h2>Usage </h2>
            {/* <UsageContainer errorInSims = {this.state.errorInSims} sims={this.state.sims} isSimsLoaded={this.state.isSimsLoaded}/>         */}
            {this.props.sims &&
              <UsageComponent sim={this.props.sims[this.state.selectedSimIndex]} />
            }
          </Grid>
          
        </Grid>
        
      </div>
        );
    }
}
