import React, {Component} from "react";
import Grid from 'react-bootstrap/lib/Grid.js'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FacebookIcon from './images/Facebook.png';
import GoogleIcon from './images/Google+.png';
import LinkedinIcon from './images/Linkedin.png';
import TwitterIcon from './images/Twitter.png';

const styles = theme => ({
    root: {
      flexGrow: 1,
      
    },
    mapFrame:{
        margin: '1%',
        marginLeft:'2%',
        marginRight: '2%',
        backgroundColor: '#ECECEC',
    },
    social:{
        height:'50vh',
        backgroundColor: '#EF6644',
        fontFamily: 'Open Sans,sans-serif',
        color: 'white'
    },
    paper: {
      padding: theme.spacing.unit * 2,
      margin: 'auto',
      maxWidth: 500,
    },
    image: {
      width: 128,
      height: 128,
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  });


function ContactUs(props) {
    const { classes } = props;
    return(
        <div className={classes.root}>
            <div className={classes.mapFrame} container col-xs-12 col-md-12 col-sm-12>
                <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.3060845270898!2d78.34536931493342!3d17.44505810573086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93a2f3e4b15d%3A0x8de838fcf87df2f!2sCellfish(Tinkerix+Technologies+Pvt+Ltd)!5e0!3m2!1sen!2sin!4v1497526663881" width="100%" height={500} frameBorder={0} style={{border: 0, zIndex: 1030}} allowFullScreen />
            </div>
            <div className={classes.social}  col-xs-12 col-sm-5 col-md-5  col-sm-offset-1 col-lg-offset-1>
                <div className="aboutSocial col-xs-12 col-sm-5 col-md-5  col-sm-offset-1 col-lg-offset-1">
                    <h3>Get Social</h3><br />
                    <a href="http://www.facebook.com/TinkerixTechnology" target="_blank"><img className="aboutSocialIcon" height="50px" width="50px" src={FacebookIcon} alt="facebook"/></a>
                    <a href="http://www.twitter.com/cellfishapp" target="_blank"> <img className="aboutSocialIcon" height="50px" width="50px" src={TwitterIcon} alt="twitter"/></a>
                    <img className="aboutSocialIcon" height="50px" width="50px" src={LinkedinIcon} alt="linkedin"/>
                    <img className="aboutSocialIcon" height="50px" width="50px" src={GoogleIcon} alt="google" /><br /><br />
                    <h3>Contact Info</h3><br />
                    <p className="aboutLocation">Cellfish(Tinkerix Technologies Pvt Ltd)<br />
                        T-Hub, IIIT-Hyderabad Campus, Gachibowli, <br /> Hyderabad, Telangana 500032<br /><br />info@tinkerix.com<br />+91 9849519676</p>
                </div>
                <div className="aboutQuery col-xs-12 col-sm-6 col-md-6"> 
                    <div className="aboutSocial  col-xs-12 col-sm-12 col-md-12">
                        <h3>Get in touch with Cellfish</h3><p>Send us your Query, we will reply you as soon as possible.</p>
                    </div>
                    <div className="submitQuery col-xs-12 col-sm-12 col-md-12">
                        <a className="typeform-share button" href="https://cellfish.typeform.com/to/u4PGWN" data-mode="popup" style={{display: 'inline-block', textDecoration: 'none', backgroundColor: 'white', color: '#EF7657', cursor: 'pointer', fontFamily: 'Helvetica,Arial,sans-serif', fontSize: '21px', lineHeight: '80px', textAlign: 'center', margin: 0, height: '80px', padding: '0px 33px', borderRadius: '40px', maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold', WebkitFontSmoothing: 'antialiased', MozOsxFontSmoothing: 'grayscale'}} data-hide-headers="true" data-hide-footer="true" target="_blank">Submit a query </a> 
                    </div>
                </div>
            </div>

        </div>

    );
}
ContactUs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactUs);