import React, { Component } from 'react';
import '../../css/Footer.css'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Icon from '@material-ui/core/Icon';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Typography from '@material-ui/core/Typography';
import FacebookIcon from '../../images/Facebook.png'
import TwitterIcon from '../../images/Twitter.png'


class Footer extends Component{
  render(){
    const { classes } = this.props;
    return(
      <div className="footer-container hidden-xs">
        <div className="center mw9 pa3 flex flex-column flex-row-ns">
          <div className="flex-auto pa4-ns pa3">
            <h3 className="pv3">
              Support
            </h3>
            <ul className="list p10">
              <li className="pv1">FAQ</li>
              <li className="pv1">Contact Us</li>
            </ul>
          </div>
          <div className="flex-auto pa4-ns pa3">
            <h3 className="pv3">
              Legal
            </h3>
            <ul className="list p10">
              <li className="pv1">Terms and Condition</li>
              <li className="pv1">Privacy</li>
              <li className="pv1">Refund</li>
            </ul>
          </div>
          <div className="flex-auto pa4-ns pa3">
            <h3 className="pv3">
              Our Company
            </h3>
            <ul className="list p10">
              <li className="pv1">About us</li>
              <li className="pv1">Career</li>
            </ul>
          </div>
          <div className="flex-auto pa4-ns pa3">
            <h3 className="pv3">
              Resource
            </h3>
            <ul className="list p10">
              <li className="pv1">Blog</li>
            </ul>
          </div>
          <div className="flex-auto pa4-ns pa3">
            <iframe className="p10"id={1} src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FTinkerixTechnology&tabs=timeline&width=271&height=300&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId" width="100%" height={300} style={{overflow: 'hidden'}} scrolling="no" frameBorder={0} allowTransparency="true" />
          </div>
        </div>
        <div className="mb5 tc">
          
          <div>
            <h3 style={{display: 'inline'}}>Connect With Us:</h3>
            <img style={{display: 'inline',width: '50px',marginTop: '5px'}} src={TwitterIcon}/>
            <img style={{display: 'inline',width: '50px',marginTop: '5px'}} src={FacebookIcon}/>
          </div>
        </div>
      </div>
    );
  }   
}



export default Footer;