import React,{Component} from 'react'
import *  as  ROUTES from'../../constants/routes'
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { PasswordForgetLink } from '../PasswordForget';
import { SignUpLink } from '../SignUp';
import { withFirebase } from '../Firebase';

import logoCellfish from '../../images/logo1.png' 
import '../../css/login.sass';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';


const SignInPage = () => (
    <div>
      <SignInForm />
    </div>
);
const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  card: {
    maxWidth: '40vw',
    height : '60vh',
    alignContent: 'center',
    marginBottom: '5%',
    margin: '0 auto',
    marginTop : '5%'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '50%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#673AB7',
    fontSize: 13,
    color: 'white',
  },

}));

const INITIAL_STATE = {
    email  : '',
    password : '',
    error : null,
}
class SignInFormBase extends Component{
    constructor(props){
        super(props);
        this.state = {...INITIAL_STATE};
    }

    onSubmit = event => {
        const { email, password,error } = this.state;
        
        this.props.firebase
          .doSignInWithEmailAndPassword(email, password)
          .then(() => {
            this.setState({ ...INITIAL_STATE });
            this.props.history.push(ROUTES.HOME);
          })
          .catch(error => {
            this.setState({ error });
          });
    
        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
      const {
        email,
        password,
        error,
      } = this.state;
  
      const isInvalid =
        password === '' ||
        email === '';
  
        return (
          <div> 
            <SignIn email={email} password={password} onSubmit={this.onSubmit} onChange={this.onChange} error={error} isInvalid={isInvalid}/>
          </div>
        );
    }
}

class Logo extends React.Component {
  render() {
    return <div className="logo">
                <img src={logoCellfish} aria-hidden="true" alt="cellfishLogo"/>
              </div>
    }
}

function SignIn(props) {
  const classes = useStyles();
  
  return (
    <Card component="main" className={classes.card}>
      <CssBaseline />
      <div className={classes.paper}>
        <Logo/>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={props.onSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="email"
            value={props.email}
            onChange={props.onChange}
            type="text"
            placeholder="Email Address"
            
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            value={props.password}
            onChange={props.onChange}
            type="password"
            placeholder="Password"
          />
          
          <Button
            disabled={props.isInvalid}
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <PasswordForgetLink variant="body2" style={{fontSize: '13'}}>
                Forgot password?
              </PasswordForgetLink>
            </Grid>
            <Grid item>
              <SignUpLink  variant="body2">
                {"Don't have an account? Sign Up"}
              </SignUpLink>
            </Grid>
          </Grid>
          {props.error && <p>{props.error.message}</p>}
        </form>
      </div>
    </Card>
  );
}

const SignInForm = compose(
    withRouter,
    withFirebase,
)(SignInFormBase);
  
export default SignInPage;

export { SignInForm,Logo };
