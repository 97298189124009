import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Logo } from '../SignIn';

const SignUpPage = () => (
  <div>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  isAdmin: false,
  error: null,
};

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    height:'80%',
    width: '50%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#673AB7',
    fontSize: 13,
    color: 'white',
  },
  card: {
    maxWidth: '40vw',
    height : '60vh',
    alignContent: 'center',
    marginBottom: '5%',
    margin: '0 auto',
    marginTop : '5%'
  },
}));

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { username, email, passwordOne } = this.state;
    const roles = {};

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set({
          username,
          email,
          roles,
        });
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.SIGN_IN);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';

    return (
      <div>
        <SignUp onSubmit={this.onSubmit} onChange={this.onChange} username={username} email={email} passwordOne = {passwordOne} passwordTwo={passwordTwo} error={error} isInvalid={isInvalid}/>
      </div>
      
    );
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

function SignUp(props) {
  const classes = useStyles();

  return (
    <Card component="main"  className={classes.card}>
      <CssBaseline />
      <div className={classes.paper}>
        <Logo/>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate onSubmit={props.onSubmit}>
              <TextField
                autoComplete="fname"
                variant="outlined"
                required
                fullWidth
                name="username"
                value={props.username}
                onChange={props.onChange}
                type="text"
                placeholder="Full Name"
                
              />
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                autoComplete="email"
                name="email"
                value={props.email}
                onChange={props.onChange}
                type="text"
                placeholder="Email Address"
                
              />
              <TextField
                variant="outlined"
                required
                fullWidth
                name="passwordOne"
                value={props.passwordOne}
                onChange={props.onChange}
                type="password"
                placeholder="Password"
              />
              <TextField
                variant="outlined"
                required
                fullWidth
                name="passwordTwo"
                value={props.passwordTwo}
                onChange={props.onChange}
                type="password"
                placeholder="Confirm Password"
              />
            
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={props.isInvalid}
            className={classes.submit}
            
          >
            Sign Up
          </Button>
          {props.error && <p>{props.error.message}</p>}
        </form>
        
      </div>
    </Card>
  );
}

const SignUpForm = compose(
  withRouter,
  withFirebase,
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
