import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';



const styles = {
    root: {
        width: 200,
        height: 300,
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        backgroundColor: '#673AB7',
      },
      chip: {
        
        width:500,
        height: 200,
      },
    
  };

function OutlinedChips(props) {
  const classes = props;

  
  return (
    <div className={classes.root}>
      
      <Chip style={{width:150,height:37,fontSize:14,backgroundColor: '#673AB7',color: 'white'}}
        label={props.fishcash} 
        className={classes.chip}
        variant="outlined"
      />
    </div>
  );
}

export default withStyles(styles)(OutlinedChips)
