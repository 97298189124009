
const isDev = false;

export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_OUT = '/signout';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';

const internalEmails = [
    "akhil.sreekar@gmail.com", "udaysankar.in@gmail.com", "jgoteti@gmail.com", "kautil15@gmail.com", "mr_rohitsharma@yahoo.com", "upadhyaya.piyu@gmail.com", "premakt939@gmail.com",
    "cellfishtinkerix@gmail.com", "jay93totewad@gmail.com", "mr.rohitsharma@gmail.com", "usha.goteti@gmail.com", "tinkerixtesting@gmail.com",
    "backonweb.piyush@gmail.com", "annavarapu.sudharani1972@gmail.com", "fishcointinkerix@gmail.com"
]
export const getApiHost = (email) => {
    if (isDev) {
        return LOCAL_URL;
    }
    if(internalEmails.some(e => e === email)) {
        return INT_URL;
    } else {
        return BASE_URL;
    }
}
export const BASE_URL = "https://api.cell.fish";
export const INT_URL = "https://int.cell.fish";
export const LOCAL_URL = "http://localhost:8080";

export const ABOUT = '/about';
export const CONTACT_US = '/contactus';
export const BLOG = '/blog';
export const CAREER = '/career';
export const FAQ = '/faq';
export const TNC = '/tnc';
export const PRIVACY = '/privacy';
export const REFUND = '/refund';
