import React,{Component} from 'react'

class Refund extends Component{
    render(){
        return(

            <div className="col-xs-12 col-sm-12 col-md-12">
        <div className="termsHeading col-xs-12 col-sm-12 col-md-12 text-center">
          <h2>CellFish Refund Policy</h2>
        </div>
        <div className="termsTextWrapper col-xs-12 col-sm-12 col-md-12">
          <div className="col-xs-1 col-md-2 col-sm-2" />
          <div className="termsText col-xs-10 col-md-8 com-sm-8">
            All recharges successfully completed are final. Please check the mobile number used for the recharge as we are not responsible for incorrect mobile numbers. If a payment was made and recharge failed we will refund the payment made. We will do this within 48 hours after being contacted. Please send us the mobile number, operator, circle, recharge amount, email address and time of the transaction. Note that sometimes a recharge might not go through but when it is retried later it might go through due to operator problems.<br />
            <h3 id="ECaACAJ3SaC">Who can get a refund?</h3>
            If a payment was successfully made and a recharge failed you can get a refund of the amount paid. 
            <h3 id="ECaACAWYIDm">How to get a refund?</h3>
            Write to us at <b>support@tinkerix.com</b>. Please make sure you include the mobile number, operator, circle, recharge amount, email address and time of the transaction.<br />
            <h3>When and how will the refund happen?</h3>
            All refunds will happen within 48 hours of us receiving all the required information. In some cases the payment provider (wallet, bank, credit card) might cause delays. The refund will be issued in the same mode as the original transaction.<br />
            <h3 id="ECaACArCgkj">How to contact us regarding any refund problems?</h3>
            Please contact us at <b>support@tinkerix.com</b> for any concerns, issues or questions.<br />
            <br />
            <i><b>Effective: July 22, 2015</b></i><br />
            <br />
            <br />
          </div>
          <div className="col-xs-1 col-md-2 col-sm-2" />
        </div>
        <div className="termsSeparator col-xs-12 col-sm-12 col-md-12">&nbsp;</div>.
      </div>
        );
    }
}

export default Refund;