import usageIcon from '../../../images/features/usage.svg'
import savingsIcon from '../../../images/features/savings.svg'
import manageIcon from '../../../images/features/settings.svg'
import billIcon from '../../../images/features/invoice.svg'

import usageImage from '../../../images/cellscreens/usage.jpeg'
import manageImage from '../../../images/cellscreens/manage.png'
import recommendationImage from '../../../images/cellscreens/recommendation.jpeg'
import billImage from '../../../images/cellscreens/bill.jpeg'


export default [
    {
        name: 'usage',
        heading: 'Understand Your Usage',
        info: 'View accurate monthly statistics for all types of calls, SMS and data usage on your phone.',
        icon: usageIcon,
        image: usageImage

    },
    {
        name: 'savings',
        heading: 'Save 40-70% Of Your Monthly Bill',
        info: 'Buy the right data plan and call/sms rate cutters appropriate for your usage. Even better, Cellfish will automatically manage them.',
        icon: savingsIcon,
        image: recommendationImage
    },
    {
        name: 'manage',
        heading: 'Manage All Your SIMS',
        info: 'Automatically recharge Sims of your loved ones. Save their monthly bill too just like yours. Get notified when they need a recharge.',
        icon: manageIcon,
        image: manageImage
    },
    {
        name: 'bill',
        heading: 'Itemized Bill Of Your Calls, Data and Sms',
        info: 'Get an account statment just like Postpaid. Not just at the end of the month but anytime or anywhere. Get a detailed statement for your data usage too!',
        icon: billIcon,
        image: billImage
    }
]