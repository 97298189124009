import React from 'react';
import ReactDOM from 'react-dom';
// import { config } from "dotenv";
// require('dotenv').config();

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'tachyons/css/tachyons.css'
import 'tachyons-flexbox/css/tachyons-flexbox.min.css'
import 'tachyons-flexbox/css/tachyons-base.min.css'
import { BrowserRouter } from 'react-router-dom';
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import 'font-awesome/css/font-awesome.min.css';
import Firebase, { FirebaseContext } from './components/Firebase';




ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </FirebaseContext.Provider>,
    
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
