import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/Header.js'
import AppContent from './components/appcontent/AppContent.js'
import Footer2 from './components/footer/Footer2.js'
import { Route, Switch } from 'react-router-dom';
import About from './components/about/About'
import ContactUs from './components/contactus/ContactUs';
import Blog from './components/blog/Blog';
import Career from './components/career/Career'
import NewHeader from './components/header/NewHeader';
import Tnc from './components/tnc/Tnc'
import './index.css'
import Privacy from './components/privacy/privacy';
import Refund from './components/refund/Refund'
import SignInPage from './components/SignIn'
import HomePage from './components/Home';
import Navigation from './components/Navigation';
import { withAuthentication } from './components/Session';
import * as ROUTES from './constants/routes';
import { SignUpForm } from './components/SignUp';
import { PasswordForgetForm } from './components/PasswordForget';


class App extends Component {
  

  render() {
    return (
      <div className="App">

        <Navigation />
        <div style={{ marginTop: 60 }}>
          <Switch style={{ margin: 0 }}>
            <Route exact path='/' component={AppContent} />
            <Route path={ROUTES.ABOUT} component={About} />
            <Route path={ROUTES.CONTACT_US} component={ContactUs} />
            <Route path={ROUTES.BLOG} component={Blog} />
            <Route path={ROUTES.CAREER} component={Career} />
            <Route path={ROUTES.FAQ} component={Blog} />
            <Route path={ROUTES.TNC} component={Tnc}></Route>
            <Route path={ROUTES.PRIVACY} component={Privacy}></Route>
            <Route path={ROUTES.REFUND} component={Refund}></Route>
            <Route path={ROUTES.SIGN_IN} component={SignInPage}></Route>
            <Route path={ROUTES.HOME} component={HomePage} />
            <Route path={ROUTES.SIGN_UP} component={SignUpForm} />
            <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetForm} />
          </Switch>
        </div>

        <Footer2 />
      </div>
    );
  }
}

export default withAuthentication(App);
