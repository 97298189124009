import React, { Component } from 'react';
import InNews from '../appcontent/InNews';
import './about.css'
import teamMember1 from './images/janaki1.png';
import teamMember2 from './images/rohitsharma.png';

const teamInfo = [
 {
   name: "Janaki Ram Goteti" ,
   info : "JanakiRam Goteti is the tech wizard behind CellFish. With a Machine Learning wand in one hand and Natural Language Processing wand in the other Janaki weaves the magic that our customers benefit from! With a Computer Science BTech from IIT Bombay and MS from University of Maryland, Janaki invested years inventing algorithms to mine and optimise Big Data and sharpen Intelligent Search until he felt he wanted to give back to his country by utilising his experience to solve problems faced by the teeming hundreds of millions of Indians. With CellFish, he feels he's just getting started.",
   img : teamMember1
 },
 {
   name : "Rohit Sharma",
   info : "Rohit Sharma is our business guru. From finding new ways to save our customers money to giving them more to cheer for, Rohit enjoys pioneering. Graduating from IIT Bombay with a BTech in Computer Science, Rohit got an MS from Dartmouth College. After a successful career in technology consulting he leapt into finance armed with an MBA from UChicago Booth School of Business. Rohit returned to give back to his country and wanted to utilize his business acumen to solve financial problems using technology. Luckily for him, FinTech was just getting coined as a buzzword",
   img : teamMember2
 } 
];
class About extends Component {
  render() {
    return (
      <div>
        <div id="careerMain" className="termsBackground col-xs-12 col-sm-12">
        <div className="col-sm-12 col-xs-12">
          <div className="col-sm-3" />
          <div id="whyTinkerix" className=" txt-center col-sm-6 col-xs-12">
            <br />
            <h1 style={{color: 'white'}}>Tinkerix Technology</h1>
            <p>At Tinkerix, we take the average Indian's problems very seriously. We are a young company that wants to help take away the problems that you face in your daily life. We want to use new technology to make life simple. So you have more time for your life! Our first product is a mobile bill management app called CellFish.It manages your mobile spend efficiently and reduces your total cost of mobile use. And the best of all? It does this silently. CellFish is a miser. It will spend as little as necessary so you have a smooth mobile use experience.</p><br />
          </div>
        </div>
      </div>
      <TeamMember member = {teamInfo[0]} />
      <TeamMember member = {teamInfo[1]}/>
      </div>
  );
  }
}

class TeamMember extends Component{
 
  render(){
    return(
      <div>
       <div >
          {/* <div className="team-image-container">

          </div>
          <div className="team-info-container">


          </div> */}

        <div  className="item col-sm-12 col-xs-12 col-lg-12 col-md-12">
          <div className="col-sm-12 col-xs-12 col-lg-4 col-md-4">
            <p className="userImage"><img src={this.props.member.img} alt="" /></p>
          </div>
          <div className="userText col-sm-12 col-xs-12 col-lg-8 col-md-8">
            <p className="testimonial-text">{this.props.member.info}</p> 
            <p className="testimonial-text" style={{textAlign: 'right', color: '#F8A102'}}><strong>{this.props.member.name}<br />Co-Founder</strong></p> 
          </div>
        </div>
       </div>
      </div>

    );
  }
}
export default About;