import React,{Component} from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import HeaderLogo from './headerlogo.png'
import './header.css'
import PlayStoreImage from './playstore1.png'
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';


class NewHeader extends Component{
    constructor(props){
      super(props);
      this.toggleNavbar = this.toggleNavbar.bind(this);
      this.state={
        collapsed: true,
        authUser: JSON.parse(localStorage.getItem('authUser'))
      }
    }

    toggleNavbar() {
      this.setState({
        collapsed: !this.state.collapsed,
      })
    }

    onSignout = () => {
      this.props.firebase.doSignOut()
      this.props.history.push("/")
      localStorage.removeItem("authUser");
    }


    render(){
      const collapsed = this.state.collapsed;
      const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse in';
      const classTwo = collapsed ? 'navbar-toggle navbar-toggle-right collapsed' : 'navbar-toggle navbar-toggle-right';
        return(
      <nav className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button type="button" onClick={this.toggleNavbar} className={`${classTwo}`} data-toggle="collapse" data-target=".navbar-collapse">
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <a className="navbar-brand cellfish" href="http://cell.fish"><img className="headerImage"src={HeaderLogo} alt="CellFish" />
            </a>
            {/* <div id="playstoreImageIcon" className="navbar-icon"><a href="https://cellfish.app.link/savemoney"><img style={{width: '30%', height: '30%', marginBottom: '2%', marginLeft: '15%'}} src={PlayStoreImage1} alt=""/></a></div> */}
          </div>
          <div id="navbar3" className={`${classOne}`}>
            <ul className="nav navbar-nav navbar-right">
              <li className="text-center"> <Link to={ROUTES.ABOUT}>About</Link></li>
              <li className="text-center"><Link to={ROUTES.CONTACT_US}>Contact Us</Link></li>
              <li className="text-center"><Link to={ROUTES.BLOG}>Blog</Link></li>
              <li className="text-center"><Link to={ROUTES.CAREER}>Career</Link></li>
              {this.props.authUser && <li className="text-center"><a onClick={this.onSignout}> Sign Out</a></li>}
              {!(this.props.authUser) && <li className="text-center"><Link to={ROUTES.SIGN_IN}> Sign In</Link></li>}
              <li className="text-center"><a href="https://cellfish.app.link/savemoney"><img id="playstoreImage" src={PlayStoreImage} alt="Google playstore" /></a></li>
              <li className=" visible-xs col-xs-6 col-xs-offset-3 text-center">
                <a className="col-xs-3" href="http://www.facebook.com/TinkerixTechnology"><i className="fa headerSocialIcons"></i></a>
                <a className="col-xs-3" href="http://www.linkedin.com"><i className="fa headerSocialIcons"></i></a>
                <a className="col-xs-3" href="http://www.twitter.com/cellfishapp"><i className="fa headerSocialIcons"></i></a>
                <a className="col-xs-3" href="https://www.youtube.com/channel/UCiVTDyRvsLBFes78xtobzVQ"><i className="fa headerSocialIcons"></i></a></li>
            </ul>
          </div>
          {/*/.nav-collapse */}
        </div>
        {/*/.container-fluid */}
      </nav>
        );
    }
}
export default withFirebase(NewHeader);