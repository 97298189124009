import React, { Component } from 'react';
import '../../css/AppContent.css'
import { TweenLite,TimelineMax } from 'gsap'
import press1 from '../../images/press/press-1.png';
import press2 from '../../images/press/press-2.png'
import press3 from '../../images/press/press-3.png'
import press4 from '../../images/press/press-4.png'
import press5 from '../../images/press/press-5.png'
import press6 from '../../images/press/press-6.png'

var Marquee = require('react-marquee');


const SLIDE_TO_SHOW = 3;
const WIDTH = 320;
const HEIGHT = 180;
const DURATION = 9;
const data = [1, 2, 3, 4, 5, 6];
const news = [
  { 
    link: "//inc42.com/buzz/gsf-maccelerator-batch-1/",
    image: press1  
  },
  {
    link: "//techcircle.vccircle.com/2015/05/08/meet-eight-startups-selected-by-gsf-for-m-accelerator/",
    image: press2
  },
  {
    link: "//www.techinasia.com/gsf-mobile-accelerator-takes-indian-startups-san-francisco-berlin-singapore/",
    image: press3
  },
  {
    link: "//www.iamwire.com/2015/05/gsfs-m-accelerator-kicks-8-mobile-startups/116008",
    image: press4
  },
  {
    link: "//www.nextbigwhat.com/gsf-mobile-startups-297/",
    image: press5
  },
  {
    link: "//yourstory.com/2015/05/gsf-mobile-accelerator/",
    image: press6
  }  
];
const tl = new TimelineMax({
  repeat: -1,
});

class InNews extends Component{
  componentDidMount() {
    const { content } = this.refs;
    tl.add(TweenLite.to(content, DURATION, {
      transform: `translate3d(${news.length * WIDTH * -1}px, 0, 0)`, 
      ease: 'easeNone', 
    }));
    tl.add(TweenLite.to(content, 0, { 
      transform: `translate3d(0, 0, 0)`
    }));
  }
  render(){
    return(
      <div 
        className="marquee press--band hidden-xs"
        style={{
          width: `${SLIDE_TO_SHOW * WIDTH}px`,
          height: `${HEIGHT}px`,
        }}
      >
        <div
          className="child"
          style={{
            width: `${(news.length + SLIDE_TO_SHOW) * WIDTH}px`,
          }}
          ref="content"
          onMouseEnter={() => {
            tl.pause();
          }}
          onMouseLeave={() => {
            tl.play();
          }}
        >
          {
            news.map((item,index) => {
              return (
                <NewsItem  key={index} newsData={item}/>
              );
            })
          }
          {
            news.map((item,index) => {
              if (index + 1 <= SLIDE_TO_SHOW) {
                return (
                  <NewsItem  key={index} newsData={item}/>
                ); 
              }
              return null;
            })
          }
        </div>
      </div>
    );
  }
}

class NewsItem extends Component{
  render(){
  
    return(
      <div className="press-col">
        <a href={this.props.newsData.link} target="_blank"><img src={this.props.newsData.image} alt="Press releases" /></a>
      </div>
    );
  }
}
export default InNews;