import React from 'react';
import { Link } from 'react-router-dom';

import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import NewHeader from '../header/NewHeader';
import Header from '../Header';
import Footer from '../footer/Footer';
import { withRouter } from 'react-router-dom';
import HomePage from '../Home';
import AppContent from '../appcontent/AppContent';

const Navigation = withRouter(({ history }) => (
  
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} history={history}/>
      ) : (
        <NavigationNonAuth authUser={authUser} history={history}/>
      )
    }
  </AuthUserContext.Consumer>
));

const NavigationAuth = ({ authUser, history }) => (
  <div>
     <NewHeader authUser={authUser} history={history}/>
     {/* <HomePage style={{marginTop:'10%'}}/> */}
  </div>
   
 
);

const NavigationNonAuth = ({ history, authUser }) => (
  <div>
    <NewHeader authUser={authUser} history={history}/>
    {/* <AppContent style={{marginTop:'10%'}}/> */}
  </div>
);

export default Navigation;
