import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CardHeader } from '@material-ui/core';
import UsageElement from './usageElement'


const styles = {
  card: {
    overflow: 'auto',
    width: '40vw',
    height : '65vh',
    backgroundColor : '#F7F7F7',
  },
  
  
};

function MediaCard(props) {
  const { classes } = props;
  return (
    <Card className={classes.card}>
        <CardHeader
          title={props.simName}>
        </CardHeader>
        <CardContent>
          <UsageElement classIcon = {'fa fa-signal fa-3x icon_dimen'} tag={'DATA'} usage = {(props.usage.dataUsageMB).toFixed(1)} unit={'MB'}/>
          <UsageElement classIcon = {'fa fa-phone fa-3x icon_dimen'} tag={'LOCAL'} usage={props.usage.voiceLocalUsage } unit={'MIN'}/>
          <UsageElement classIcon = {'fa fa-envelope-o fa-3x icon_dimen'} tag={'SMS'} usage={props.usage.smsLocalUsage + props.usage.smsStdUsage} unit={'SMS'}/>
          <UsageElement classIcon = {'fa fa-phone fa-3x icon_dimen'}tag={'STD'} usage={props.usage.voiceStdUsage} unit={'MIN'}/>
          {/* <Typography gutterBottom variant="h3" component="h1">
           
          </Typography>
          <Typography variant="h6" component="h4">
            usage
          </Typography> */}
        </CardContent>
      
    </Card>
  );
}

MediaCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MediaCard);
