import React, { Component } from 'react';
import axios from 'axios';
import * as ROUTES from '../../constants/routes';
import SimCard from './simCard'
import TitlebarGridList from './simGridList'
import Popup from "reactjs-popup";
import { config } from 'dotenv';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getApiHost } from '../../constants/routes';




class SimList extends Component{
    
    constructor(props){
        super(props);
        this.state={
            authUser: JSON.parse(localStorage.getItem('authUser')),
            isChecked: false,
        }
        
    }
    
    onSimCheckboxClick = (i,status) => {
        this.props.sims[i].isEnabled = status;
        this.setState({ 
            selectedSimIndex: i, 
            isChecked: status,
        });
       if(status){
        let days = prompt("For how many days do you want to enable the sim <b>"+this.props.sims[i].nickname +"</b> ?\nPlease enter -1 to keep it enebled forever");
        if(days !== null){
            this.enableAutoBuy(this.props.sims[i].id,parseInt(days));
        }else{
            this.props.sims[i].isEnabled = !status;
            alert("enable sim cancelled");
        }
       }else{
         this.disableAutoBuy(this.props.sims[i].id);
       }
    }

    enableAutoBuy(simid,duration){
      const token = this.state.authUser.idToken;
        var config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const urlToLink = getApiHost(this.state.authUser.email)+"/v1/user/enableAutoBuy";
        const formdata = new FormData();
        formdata.append('simid', simid);
        const data = {
            'simid' : simid,
            'duration' : duration,
        }

        axios.request({
          method: 'POST',
          url: urlToLink,data: require('querystring').stringify(data),headers: config.headers,
      })
      .then((response) => {
          console.log("disable autobuy response",response);
          alert("Successfully enabled sim");
      })
      .catch((error) => {
          console.log("disable autobuy error",error);
          alert("Unable to enabled sim");
      })

    }

    disableAutoBuy(simid){
        const token = this.state.authUser.idToken;
        var config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const urlToLink = getApiHost(this.state.authUser.email)+"/v1/user/disableAutoBuy";
        const formdata = new FormData();
        formdata.append('simid', simid);
        const data = {
            'simid' : simid,
        }
        // const data = {
        //     'simid' : simid,
        // }
        axios.request({
            method: 'POST',
            url: urlToLink,data: require('querystring').stringify(data),headers: config.headers,
        })
        .then((response) => {
            console.log("disable autobuy response",response);
            alert("Successfully disabled sim");
        })
        .catch((error) => {
            console.log("disable autobuy error",error);
            alert("Unable to disable sim");
        })

    }

    render(){
        const { errorInSims, isSimsLoaded, sims, onClick } = this.props;
        // if(errorInSims){
        //     return <div>Error : {errorInSims.message}</div>
        // }else if(!isSimsLoaded){
        //     return <div>Loading... </div>
        // }else{
            
            return(
                <div>
                    {sims &&<TitlebarGridList simData={sims} onClick={onClick} onCheckBoxClicked={this.onSimCheckboxClick} isChecked={this.state.isChecked} />}
                </div>
            
            );
        }
    
}
function FormDialog() {
    const [open, setOpen] = React.useState(true);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <div>
        {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Open form dialog
        </Button> */}
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To subscribe to this website, please enter your email address here. We will send updates
              occasionally.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleClose} color="primary">
              Subscribe
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
export default SimList;