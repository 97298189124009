import React, {Component} from 'react';

class Blog extends Component{
    render(){
        return(
            <div>
                <div className="blogBackground" container col-xs-12 col-sm-12 col-md-12>
                    <div style={{width: '100%', height: '0', paddingBottom: '50%', position: 'relative'}}>
                      <iframe title="No Blog" src="https://giphy.com/embed/uE7kEF34HJG5q" width="100%" height="100%" style={{position: 'absolute'}} frameBorder={0} className="giphy-embed" allowFullScreen />
                     </div>
                </div>

            </div>

        );
    }
}

export default Blog;