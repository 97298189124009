import React, { Component } from 'react';
import './appview2.css'
import phoneFrame from '../../../images/phonescreenfinal.png'

import Tab from './Tab'
import info from './info'

// const imageMap={
//   usage: usageIcon,
//   savings: savingsIcon,
//   manage: manageIcon,
//   bill: billIcon
// };
class AppView2 extends Component{
  
  state = {
    activeTab: 'usage'
  }

  // constructor() {
  //   super()
  //   this.state = {

  //   }
  //   const getActiveTab = this.getActiveTab.bind(this)
  // }

  getActiveTab = (tabName) =>  {
    // alert(activeTab)
    this.setState({activeTab: tabName})
  }

  render(){
    const {activeTab} = this.state
    return(
      <div className="appview2-container hidden-xs">
        
        {/* <div className="feature-container"> */}
          {/* <div className="feature-info-container"> */}
            <TabInfo activeTab={activeTab} />
          {/* </div> */}
          {/* <div className="feature-image-container"> */}
            
          {/* </div> */}
        {/* </div> */}
        
        <div className="feature-tab-container">
            <Tabs getActiveTab={this.getActiveTab} />
        </div>
      </div>
      
    );
  }
}



class TabInfo extends Component{
  render(){
    
    const {activeTab} = this.props
    return(
      <div >
        {/* <div className="feature-content"> */}
          {/* <div className="feature-image-container feature-image visible-xs">
            <img src="images/cellscreens/usage.jpeg" alt="Phone usage" />
          </div> */}
          {
            info.map(obj => (
              obj.name === activeTab &&
              <div className="feature-content feature-container">
                <div className="feature-info-container">
                  <div className="feature-div">
                    <div className="icon-wrapper ">
                        <img style={{height: '150px', width: '150px'}} src={obj.icon} alt="usageIcon"/>
                      </div>
                      <div className="_comp--title-copy">
                        <h4 className="_comp__tc--title feature-title">{obj.heading}</h4>
                        <p className="_comp__tc--copy featureText">
                          {
                            obj.info
                          }
                        </p>
                      </div>
                  </div>
                </div>  
                <div className="feature-image-container">
                  <div className="phone-frame-div">
                    <img src={phoneFrame} alt="Phone usage" className="phone-frame-image" />
                    <img src={obj.image} alt="In Frame" className="image-in-frame"/>
                  </div>
                
                </div>
              </div>
            ))
          }
        
      </div>
      
    );
  }
}

class Tabs extends Component{

  render(){
    const {getActiveTab} = this.props
    return(
        <div className="row-feature full-h">
            <ul className="features-tab-list">
              <li>
                <Tab getActiveTab={getActiveTab} name='usage'>
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 60" style={{enableBackground: 'new 0 0 60 60'}} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{__html: "\n\n                  .st1{fill:#ffffff;}\n                  .st2{fill:#ffffff;}\n                " }} />
                    <g>
                      <g>
                        <path className="st0" d="M15.8,27.3l-5,5c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l5-5
                          c0.4-0.4,0.4-1,0-1.4S16.2,26.9,15.8,27.3z" />
                        <path className="st0" d="M25.2,27.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6,6c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3
                          c0.4-0.4,0.4-1,0-1.4L25.2,27.3z" />
                        <path className="st0" d="M48.2,24.3c-0.4-0.4-1-0.4-1.4,0l-10,10c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l10-10
                          C48.6,25.3,48.6,24.7,48.2,24.3z" />
                      </g>
                      <circle className="st1" cx="8.5" cy={37} r={5} />
                      <circle className="st2" cx="20.5" cy={25} r={5} />
                      <circle className="st2" cx="51.5" cy={22} r={5} />
                      <circle className="st1" cx="33.5" cy={38} r={5} />
                    </g>
                  </svg>
                </Tab>
              </li>
              <li>
                <Tab getActiveTab={getActiveTab} name='savings'>
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 350 350" style={{enableBackground: 'new 0 0 350 350'}} xmlSpace="preserve">
                  <g id="XMLID_820_">
                    <g id="XMLID_821_">
                      <g id="XMLID_822_">
                        <rect id="XMLID_50_" x={15} y={280} style={{fill: '#ffffff'}} width={40} height={70} />
                      </g>
                      <rect id="XMLID_48_" x={15} y={210} style={{fill: '#ffffff'}} width={40} height={70} />
                      <path id="XMLID_823_" style={{fill: '#ffffff'}} d="M331.772,249.118c-6.41-10.645-20.236-14.078-30.883-7.668l-72.639,37.113
                        c4.164-4.083,6.75-9.771,6.75-16.064c0-12.427-10.074-22.5-22.5-22.5h-68.211C116,240,102.668,220,55,220c0,50.345,0,100,0,100
                        s13,9,30,20c15.17,9.815,22.291,10,33.838,10l74.496-0.027c9.332,0,18.666-3.973,29-8.973s101.77-61,101.77-61
                        C334.75,273.589,338.182,259.764,331.772,249.118z" />
                      <path id="XMLID_825_" style={{fill: '#ffffff'}} d="M55,220v100c0,0,13.001,9,30.001,20c15.17,9.815,22.29,10,33.837,10l46.163-0.017
                        L55,220z" />
                    </g>
                    <g id="XMLID_826_">
                      <g id="XMLID_827_">
                        <circle id="XMLID_828_" style={{fill: '#ffffff'}} cx={220} cy={175} r={45} />
                        <path id="XMLID_829_" style={{fill: '#ffffff'}} d="M220,220c-24.853,0-45-20.148-45-45s20.147-45,45-45" />
                        <circle id="XMLID_830_" style={{fill: '#ffffff'}} cx={220} cy={175} r={25} />
                      </g>
                      <g id="XMLID_831_">
                        <circle id="XMLID_832_" style={{fill: '#ffffff'}} cx={110} cy={135} r={45} />
                        <path id="XMLID_833_" style={{fill: '#ffffff'}} d="M110,180c-24.853,0-45-20.148-45-45s20.147-45,45-45" />
                        <circle id="XMLID_834_" style={{fill: '#ffffff'}} cx={110} cy={135} r={25} />
                      </g>
                      <g id="XMLID_835_">
                        <circle id="XMLID_836_" style={{fill: '#ffffff'}} cx={180} cy={45} r={45} />
                        <path id="XMLID_837_" style={{fill: '#ffffff'}} d="M180,90c-24.853,0-45-20.147-45-45s20.147-45,45-45" />
                        <circle id="XMLID_838_" style={{fill: '#ffffff'}} cx={180} cy={45} r={25} />
                      </g>
                    </g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                </svg>
                </Tab>
              </li>
              <li>
                <Tab getActiveTab={getActiveTab} name='manage'>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 490.2 490.2" style={{enableBackground: 'new 0 0 490.2 490.2'}} xmlSpace="preserve">
                  <style type="text/css" dangerouslySetInnerHTML={{__html: "\n              .st0{fill:#Ffffff;}\n            " }} />
                  <g>
                    <g>
                      <path id="XMLID_698_" className="st0" d="M221.7,167.8h17.8c2.8,14.2,8.4,27.3,16.1,38.9L243,219.3c-4.5,4.5-4.5,11.8,0,16.3
                    l13.7,13.7c4.5,4.5,11.8,4.5,16.3,0l12.6-12.6c11.6,7.8,24.8,13.3,38.9,16.1v17.8c0,6.4,5.2,11.5,11.5,11.5h19.4
                    c6.4,0,11.5-5.2,11.5-11.5v-17.8c14.2-2.8,27.3-8.4,38.9-16.1l12.6,12.6c4.5,4.5,11.8,4.5,16.3,0l13.7-13.7
                    c4.5-4.5,4.5-11.8,0-16.3l-12.6-12.6c7.8-11.6,13.3-24.8,16.1-38.9h17.8c6.4,0,11.5-5.2,11.5-11.5V137c0-6.4-5.2-11.5-11.5-11.5
                    h-17.8c-2.8-14.2-8.4-27.3-16.1-38.9L448.4,74c4.5-4.5,4.5-11.8,0-16.3L434.6,44c-4.5-4.5-11.8-4.5-16.3,0l-12.6,12.6
                    c-11.6-7.8-24.8-13.3-38.9-16.1V22.7c0-6.4-5.2-11.5-11.5-11.5h-19.4c-6.4,0-11.5,5.2-11.5,11.5v17.8
                    c-14.2,2.8-27.3,8.4-38.9,16.1L273,44c-4.5-4.5-11.8-4.5-16.3,0L243,57.7c-4.5,4.5-4.5,11.8,0,16.3l12.6,12.6
                    c-7.8,11.6-13.3,24.8-16.1,38.9h-17.8c-6.4,0-11.5,5.2-11.5,11.5v19.4C210.2,162.7,215.3,167.8,221.7,167.8z M345.6,97.6
                    c27.1,0,49.1,22,49.1,49.1s-22,49.1-49.1,49.1s-49.1-22-49.1-49.1S318.5,97.6,345.6,97.6z" />
                      <path d="M346.3,380.5c-1.4,3.5-5.4,5.1-8.8,3.7l-22.6-9.4c-4-1.7-8.6-0.3-11,3.3c-10.2,15.3-23.2,28.3-38.5,38.5
                    c-3.6,2.4-5,7-3.3,11l9.3,22.6c1.4,3.4-0.2,7.4-3.7,8.8l-24.6,10.3c-3.4,1.4-7.4-0.2-8.8-3.7l-9.4-22.6c-1.7-4-5.9-6.3-10.1-5.4
                    c-18,3.6-36.3,3.6-54.4,0c-4.2-0.8-8.5,1.4-10.1,5.4l-9.4,22.6c-1.4,3.4-5.4,5.1-8.8,3.7l-24.6-10.2c-1.7-0.7-3-2-3.7-3.7
                    s-0.7-3.5,0-5.2l9.4-22.6c1.7-4,0.3-8.6-3.3-11c-15.3-10.2-28.3-23.2-38.5-38.5c-2.4-3.6-7-5-11-3.3l-22.6,9.4
                    c-3.5,1.4-7.4-0.2-8.8-3.7l-10.2-24.6c-1.4-3.4,0.2-7.4,3.7-8.8l22.6-9.4c4-1.7,6.3-5.9,5.4-10.1c-3.6-18-3.6-36.3,0-54.4
                    c0.8-4.2-1.4-8.5-5.4-10.1l-22.6-9.4c-1.7-0.7-3-2-3.7-3.7s-0.7-3.5,0-5.2L29,220.2c0.7-1.7,2-3,3.7-3.7s3.5-0.7,5.2,0l22.6,9.4
                    c4,1.7,8.6,0.3,11-3.3c10.2-15.3,23.2-28.3,38.5-38.5c3.6-2.4,5-7,3.3-11l-9.4-22.6c-0.7-1.7-0.7-3.5,0-5.2s2-3,3.7-3.7l24.6-10.2
                    c3.4-1.4,7.4,0.2,8.8,3.7l9.4,22.6c1.9,4.6,7.2,6.8,11.8,4.9c4.6-1.9,6.8-7.2,4.9-11.8l-9.4-22.6c-5.3-12.7-19.8-18.7-32.5-13.5
                    l-24.6,10.2c-6.1,2.5-10.9,7.3-13.5,13.5c-2.5,6.1-2.5,12.9,0,19.1l6.6,15.9c-12.6,9.3-23.7,20.4-33.1,33.1l-15.9-6.6
                    c-6.1-2.5-12.9-2.5-19.1,0c-6.1,2.5-10.9,7.3-13.5,13.5L1.9,238c-2.5,6.1-2.5,12.9,0,19.1c2.5,6.1,7.3,10.9,13.5,13.5l15.9,6.6
                    c-2.4,15.6-2.4,31.2,0,46.8l-15.9,6.6c-12.7,5.3-18.7,19.8-13.5,32.5l10.2,24.3c5.2,12.7,19.8,18.7,32.5,13.5l15.9-6.6
                    c9.3,12.6,20.4,23.7,33.1,33.1L87,443.3c-2.5,6.1-2.5,12.9,0,19.1c2.5,6.1,7.3,10.9,13.5,13.5l24.6,10.2
                    c12.7,5.2,27.3-0.8,32.5-13.5l6.6-15.9c15.6,2.4,31.2,2.4,46.8,0l6.6,15.9c4,9.6,13.3,15.4,23,15.4c3.2,0,6.4-0.6,9.5-1.9
                    l24.6-10.2c12.7-5.3,18.7-19.8,13.5-32.5l-6.6-15.9c12.6-9.3,23.7-20.4,33.1-33.1l15.9,6.6c12.7,5.3,27.3-0.8,32.5-13.5l10.2-24.6
                    c2.5-6.1,2.5-12.9,0-19.1c-2.5-6.1-7.3-10.9-13.5-13.5l-22.6-9.4c-4.6-1.9-9.9,0.3-11.8,4.9s0.3,9.9,4.9,11.8l22.6,9.4
                    c1.7,0.7,3,2,3.7,3.7s0.7,3.5,0,5.2L346.3,380.5z" />
                      <path d="M187.1,238.5c5,0,9-4.1,9-9.1s-4.1-9-9.1-9c0,0,0,0-0.1,0c-21.4,0.2-41.4,8.6-56.4,23.8s-23.2,35.4-23,56.7
                    c0.2,21.4,8.6,41.4,23.8,56.4c15.1,14.9,35,23,56.1,23c0.2,0,0.4,0,0.6,0c21.4-0.2,41.4-8.6,56.4-23.8c15-15.2,23.2-35.4,23-56.7
                    c0-5-4.1-9-9.1-9c0,0,0,0-0.1,0c-5,0-9,4.1-9,9.1c0.1,16.5-6.2,32.1-17.8,43.9s-27.1,18.3-43.6,18.4c-0.2,0-0.3,0-0.5,0
                    c-16.3,0-31.8-6.3-43.4-17.8c-11.8-11.6-18.3-27.1-18.4-43.6s6.2-32.1,17.8-43.9C155.1,245.2,170.6,238.6,187.1,238.5z" />
                      <path d="M469.6,176.9c11.3,0,20.6-9.2,20.6-20.6V137c0-11.3-9.2-20.6-20.6-20.6h-10.5c-2.7-10-6.6-19.6-11.8-28.5l7.5-7.5
                    c8-8,8-21.1,0-29.1l-13.7-13.7c-3.9-3.9-9.1-6-14.5-6c-5.5,0-10.7,2.1-14.5,6l-7.5,7.5c-9-5.2-18.5-9.2-28.5-11.8V22.7
                    c0-11.3-9.2-20.6-20.6-20.6h-19.4c-11.3,0-20.6,9.2-20.6,20.6v10.6c-10,2.7-19.6,6.6-28.5,11.8l-7.5-7.5c-3.9-3.9-9.1-6-14.5-6
                    c-5.5,0-10.7,2.1-14.5,6l-13.7,13.7c-8,8-8,21.1,0,29.1l7.5,7.5c-5.2,9-9.2,18.5-11.8,28.5h-10.6c-11.3,0-20.6,9.2-20.6,20.6v19.4
                    c0,11.3,9.2,20.6,20.6,20.6h10.6c2.7,10,6.6,19.6,11.8,28.5l-7.5,7.5c-8,8-8,21.1,0,29.1l13.7,13.7c3.9,3.9,9.1,6,14.5,6
                    c5.5,0,10.7-2.1,14.5-6l7.5-7.5c9,5.2,18.5,9.2,28.5,11.8v10.6c0,11.3,9.2,20.6,20.6,20.6h19.4c11.3,0,20.6-9.2,20.6-20.6V260
                    c10-2.7,19.6-6.6,28.5-11.8l7.5,7.5c3.9,3.9,9.1,6,14.5,6c5.5,0,10.7-2.1,14.5-6l13.7-13.7c8-8,8-21.1,0-29.1l-7.5-7.5
                    c5.2-9,9.2-18.5,11.8-28.5L469.6,176.9L469.6,176.9z M443,166.1c-2.5,12.8-7.5,24.8-14.8,35.7c-2.4,3.6-1.9,8.4,1.1,11.4
                    l12.6,12.6c1,1,1,2.5,0,3.5L428.2,243c-0.6,0.6-1.3,0.7-1.7,0.7c-0.5,0-1.1-0.1-1.7-0.7l-12.6-12.6c-3.1-3.1-7.9-3.5-11.4-1.1
                    c-10.9,7.3-22.9,12.2-35.7,14.8c-4.2,0.8-7.3,4.6-7.3,8.9v17.8c0,1.3-1.1,2.4-2.4,2.4H336c-1.3,0-2.4-1.1-2.4-2.4V253
                    c0-4.3-3.1-8-7.3-8.9c-12.8-2.5-24.8-7.5-35.7-14.8c-1.5-1-3.3-1.5-5-1.5c-2.3,0-4.7,0.9-6.4,2.7l-12.6,12.6
                    c-0.6,0.6-1.3,0.7-1.7,0.7c-0.5,0-1.1-0.1-1.7-0.7l-13.7-13.7c-1-1-1-2.5,0-3.5l12.6-12.6c3.1-3.1,3.5-7.9,1.1-11.4
                    c-7.3-10.9-12.2-22.9-14.8-35.7c-0.8-4.2-4.6-7.3-8.9-7.3h-17.8c-1.3,0-2.4-1.1-2.4-2.4V137c0-1.3,1.1-2.4,2.4-2.4h17.8
                    c4.3,0,8-3.1,8.9-7.3c2.5-12.8,7.5-24.8,14.8-35.7c2.4-3.6,1.9-8.4-1.1-11.4l-12.6-12.6c-0.6-0.6-0.7-1.3-0.7-1.7
                    c0-0.5,0.1-1.1,0.7-1.7l13.6-13.8c0.6-0.6,1.3-0.7,1.7-0.7c0.5,0,1.1,0.1,1.7,0.7L279.1,63c3.1,3.1,7.9,3.5,11.4,1.1
                    c10.9-7.3,22.9-12.2,35.7-14.8c4.2-0.8,7.3-4.6,7.3-8.9V22.7c0-1.3,1.1-2.4,2.4-2.4h19.4c1.3,0,2.4,1.1,2.4,2.4v17.8
                    c0,4.3,3.1,8,7.3,8.9c12.8,2.5,24.8,7.5,35.7,14.8c3.6,2.4,8.4,1.9,11.4-1.1l12.6-12.6c0.6-0.6,1.3-0.7,1.7-0.7
                    c0.5,0,1.1,0.1,1.7,0.7l13.7,13.7c1,1,1,2.5,0,3.5l-12.6,12.6c-3.1,3.1-3.5,7.9-1.1,11.4c7.3,10.9,12.2,22.9,14.8,35.7
                    c0.8,4.2,4.6,7.3,8.9,7.3h17.8c1.3,0,2.4,1.1,2.4,2.4v19.4c0,1.3-1.1,2.4-2.4,2.4h-17.8C447.5,158.8,443.8,161.8,443,166.1z" />
                      <path d="M345.7,88.5c-32.1,0-58.1,26.1-58.1,58.1c0,32.1,26.1,58.1,58.1,58.1c32.1,0,58.1-26.1,58.1-58.1S377.7,88.5,345.7,88.5z
                    M345.7,186.6c-22.1,0-40-17.9-40-40s17.9-40,40-40s40,17.9,40,40S367.7,186.6,345.7,186.6z" />
                    </g>
                  </g>
                </svg>
                </Tab>
              </li>
              <li>
                <Tab getActiveTab={getActiveTab} name='bill'>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 395.636 395.636" style={{enableBackground: 'new 0 0 395.636 395.636'}} xmlSpace="preserve">
                  <path style={{fill: '#FFFFFF', stroke: '#193651', strokeWidth: 2, strokeMiterlimit: 10}} d="M324.525,6.206
              c0,10.537-7.434,18.554-17.325,18.554c-9.244,0-17.325-8.016-17.325-18.554h-20.945c0,10.537-7.434,18.554-17.325,18.554
              c-9.244,0-17.325-8.016-17.325-18.554h-21.01c0,10.537-7.434,18.554-17.325,18.554c-9.244,0-17.325-8.016-17.325-18.554h-21.01
              c0,10.537-7.434,18.554-17.325,18.554c-9.891,0-17.325-8.016-17.325-18.554h-21.01c0,10.537-7.434,18.554-17.325,18.554
              c-9.244,0-17.325-8.016-17.325-18.554H43.248v383.289h24.113c0-10.537,7.434-18.554,17.325-18.554
              c9.244,0,17.325,8.016,17.325,18.554h21.01c0-10.537,7.434-18.554,17.325-18.554c9.891,0,17.325,8.016,17.325,18.554h21.01
              c0-10.537,7.434-18.554,17.325-18.554c9.244,0,17.325,8.016,17.325,18.554h21.01c0-10.537,7.434-18.554,17.325-18.554
              c9.244,0,17.325,8.016,17.325,18.554h21.01c0-10.537,7.434-18.554,17.325-18.554c9.244,0,17.325,8.016,17.325,18.554h27.798V6.206
              H324.525z" />
                  <path id="SVGCleanerId_0" style={{fill: '#Ffffff'}} d="M279.402,358.529c-7.434-7.434-17.325-12.347-27.798-12.347
              c-10.473,0-20.428,4.913-27.798,12.347c-7.434-7.434-17.325-12.347-27.798-12.347s-20.428,4.913-27.798,12.347
              c-7.434-7.434-17.325-12.347-27.798-12.347c-10.473,0-20.428,4.913-27.798,12.347c-7.434-7.434-17.325-12.347-27.798-12.347
              c-6.788,0-12.994,1.875-18.554,4.913V44.477c5.56,3.103,11.766,4.978,18.554,4.978c10.537,0,20.428-4.331,27.798-12.347
              c7.434,7.434,17.325,12.347,27.798,12.347c10.473,0,20.428-4.331,27.798-12.347c7.434,7.434,17.325,12.347,27.798,12.347
              s20.428-4.331,27.798-12.347c7.434,7.434,17.325,12.347,27.798,12.347c10.473,0,20.428-4.331,27.798-12.347
              c7.434,7.434,17.325,12.347,27.798,12.347c8.016,0,16.097-2.457,22.238-7.434V352.97c-6.206-4.331-14.222-7.434-22.238-7.434
              C296.727,346.182,286.836,351.095,279.402,358.529z" />
                  <path style={{fill: '#FFFFFF'}} d="M324.525,6.206c0,10.537-7.434,18.554-17.325,18.554c-9.244,0-17.325-8.016-17.325-18.554h-20.945
              c0,10.537-7.434,18.554-17.325,18.554c-9.244,0-17.325-8.016-17.325-18.554h-21.01c0,10.537-7.434,18.554-17.325,18.554
              c-9.244,0-17.325-8.016-17.325-18.554h-21.01c0,10.537-7.434,18.554-17.325,18.554c-9.891,0-17.325-8.016-17.325-18.554h-21.01
              c0,10.537-7.434,18.554-17.325,18.554c-9.244,0-17.325-8.016-17.325-18.554H43.248v383.289h24.113
              c0-10.537,7.434-18.554,17.325-18.554c9.244,0,17.325,8.016,17.325,18.554h21.01c0-10.537,7.434-18.554,17.325-18.554
              c9.891,0,17.325,8.016,17.325,18.554h21.01c0-10.537,7.434-18.554,17.325-18.554c9.244,0,17.325,8.016,17.325,18.554h21.01
              c0-10.537,7.434-18.554,17.325-18.554c9.244,0,17.325,8.016,17.325,18.554h21.01c0-10.537,7.434-18.554,17.325-18.554
              c9.244,0,17.325,8.016,17.325,18.554h27.798V6.206H324.525z" />
                  <path style={{fill: '#193651'}} d="M358.529,395.636h-40.21v-6.206c0-6.788-4.913-12.347-11.119-12.347s-11.119,5.56-11.119,12.347
              v6.206h-33.358v-6.206c0-6.788-4.913-12.347-11.119-12.347s-11.119,5.56-11.119,12.347v6.206h-33.358v-6.206
              c0-6.788-4.913-12.347-11.119-12.347s-11.119,5.56-11.119,12.347v6.206h-33.358v-6.206c0-6.788-4.913-12.347-11.119-12.347
              s-11.119,5.56-11.119,12.347v6.206H95.935v-6.206c0-6.788-4.913-12.347-11.119-12.347s-11.119,5.56-11.119,12.347v6.206h-36.59V0
              h36.461v6.206c0,6.788,4.978,12.347,11.119,12.347c6.141,0,11.119-5.56,11.119-12.347V0h33.358v6.206
              c0,6.788,4.913,12.347,11.119,12.347s11.119-5.56,11.119-12.347V0h33.358v6.206c0,6.788,4.913,12.347,11.119,12.347
              c6.206,0,11.119-5.56,11.119-12.347V0h33.487v6.206c0,6.788,4.913,12.347,11.119,12.347c6.206,0,11.119-5.56,11.119-12.347V0h33.358
              v6.206c0,6.788,4.913,12.347,11.119,12.347c6.206,0,11.119-5.56,11.119-12.347V0h40.21L358.529,395.636L358.529,395.636z
              M330.085,383.289h16.097V12.347h-16.097c-2.457,10.537-11.766,18.554-22.885,18.554c-11.119,0-19.782-8.016-22.885-18.554h-10.537
              c-2.457,10.537-11.766,18.554-22.885,18.554c-11.119,0-19.782-8.016-22.885-18.554h-10.537
              c-2.457,10.537-11.766,18.554-22.885,18.554c-11.119,0-19.782-8.016-22.885-18.554h-10.537
              c-2.457,10.537-11.766,18.554-22.885,18.554s-19.782-8.016-22.885-18.554h-10.537C102.4,22.885,93.091,30.901,81.972,30.901
              S62.19,22.885,59.087,12.347h-9.632v370.941h12.994c2.457-10.537,11.766-18.554,22.885-18.554s19.782,8.016,22.885,18.554h10.537
              c2.457-10.537,11.766-18.554,22.885-18.554s19.782,8.016,22.885,18.554h10.537c2.457-10.537,11.766-18.554,22.885-18.554
              c11.119,0,19.782,8.016,22.885,18.554h10.537c2.457-10.537,11.766-18.554,22.885-18.554c11.119,0,19.782,8.016,22.885,18.554h10.537
              c2.457-10.537,11.766-18.554,22.885-18.554S327.046,372.751,330.085,383.289z" />
                  <g>
                    <path id="SVGCleanerId_0_1_" style={{fill: '#ffffff'}} d="M279.402,358.529c-7.434-7.434-17.325-12.347-27.798-12.347
                c-10.473,0-20.428,4.913-27.798,12.347c-7.434-7.434-17.325-12.347-27.798-12.347s-20.428,4.913-27.798,12.347
                c-7.434-7.434-17.325-12.347-27.798-12.347c-10.473,0-20.428,4.913-27.798,12.347c-7.434-7.434-17.325-12.347-27.798-12.347
                c-6.788,0-12.994,1.875-18.554,4.913V44.477c5.56,3.103,11.766,4.978,18.554,4.978c10.537,0,20.428-4.331,27.798-12.347
                c7.434,7.434,17.325,12.347,27.798,12.347c10.473,0,20.428-4.331,27.798-12.347c7.434,7.434,17.325,12.347,27.798,12.347
                s20.428-4.331,27.798-12.347c7.434,7.434,17.325,12.347,27.798,12.347c10.473,0,20.428-4.331,27.798-12.347
                c7.434,7.434,17.325,12.347,27.798,12.347c8.016,0,16.097-2.457,22.238-7.434V352.97c-6.206-4.331-14.222-7.434-22.238-7.434
                C296.727,346.182,286.836,351.095,279.402,358.529z" />
                  </g>
                  <g>
                    <path style={{fill: '#193651'}} d="M113.778,259.943c-6.335,0-11.442-5.172-11.442-11.442c0-6.335,5.172-11.442,11.442-11.442
                c6.335,0,11.442,5.172,11.442,11.442h12.929c0-11.184-7.628-20.687-17.907-23.531V213.85h-12.929v11.119
                c-10.343,2.844-17.907,12.283-17.907,23.531c0,13.446,10.925,24.372,24.372,24.372c6.335,0,11.442,5.172,11.442,11.442
                c0,6.271-5.172,11.442-11.442,11.442c-6.335,0-11.442-5.172-11.442-11.442H89.406c0,11.184,7.628,20.687,17.907,23.531v9.244
                h12.929v-9.244c10.343-2.844,17.907-12.283,17.907-23.531C138.149,270.933,127.224,259.943,113.778,259.943z" />
                    <rect x="144.679" y="84.428" style={{fill: '#193651'}} width="106.343" height="12.929" />
                    <rect x="95.806" y="125.802" style={{fill: '#193651'}} width="204.024" height="12.929" />
                    <rect x="95.806" y="167.24" style={{fill: '#193651'}} width="204.024" height="12.929" />
                    <polygon style={{fill: '#193651'}} points="302.287,284.638 302.287,271.709 239.321,271.709 238.61,271.709 238.61,284.638
                239.321,284.638   " />
                    <polygon style={{fill: '#193651'}} points="239.321,300.8 238.61,300.8 238.61,313.729 239.321,313.729 250.311,313.729
                250.958,313.729 250.958,300.8 250.311,300.8   " />
                    <polygon style={{fill: '#193651'}} points="264.598,300.8 264.275,300.8 264.275,313.729 264.598,313.729 276.945,313.729
                277.204,313.729 277.204,300.8 276.945,300.8   " />
                    <polygon style={{fill: '#193651'}} points="302.287,300.8 289.939,300.8 289.616,300.8 289.616,313.729 289.939,313.729
                302.287,313.729 302.545,313.729 302.545,300.8   " />
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                  <g>
                  </g>
                </svg>
                </Tab>
              </li>
            </ul>
          
      
      </div>
      
    );
  }
}

export default AppView2;
