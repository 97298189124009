import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './title';

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

function getDate(){
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    return "On "+ dd +" "+monthNames[today.getMonth()]+", "+today.getFullYear();
}

export default function FishCash(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Title>Recent FishCash Present</Title>
      <Typography component="p" variant="h4">
      ₹ {props.fishcash} 
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        {getDate()}
      </Typography>
      {/* <div>
        <Link color="primary" href="javascript:;">
          View balance
        </Link>
      </div> */}
    </React.Fragment>
  );
}