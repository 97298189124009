import React, {Component} from 'react';
import './career.css';
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';


class Career extends Component{

    render(){
        return(
            <div >
                <div  className="backgroundWrapper"  col-sm-12 col-xs-12 col-md-12 col-lg-12>
                    <div id="backgroundTextWrapper" col-sm-offset-1 col-xs-offset-1 col-xs-11 col-sm-11>
                        <div>
                        <p className="careerText text-center">Jobs at Cellfish</p>
                         </div>
                    </div>
                 </div>
                 <div id="careerMain" col-xs-12 col-sm-12 >
                    <div col-sm-12 col-xs-12 style={{width:'70%',margin:'0 auto'}}>
                        <div col-sm-3/>
                        <div  id="whyTinkerix"className="txt-center"col-sm-6 col-xs-12>
                            <br />
                            <h1 style={{color: 'white',marginLeft: '40%'}}>The Technology</h1>
                            <p style={{color:'white',lineHeight:'180%',fontSize:'2rem',textAign:'center'}}>At Tinkerix, we take the average Indian's problems very seriously. We are a young company that wants to help take away the problems that you face in your daily life. We want to use new technology to make life simple. So you have more time for your life! Our first product is a mobile bill management app called Cellfish. It manages your mobile spend efficiently and reduces your total cost of mobile use. And the best of all? It does this silently. Cellfish is a miser. It will spend as little as necessary so you have a smooth mobile use experience.</p><br />
                            <h3 style={{color: 'white',marginLeft: '40%'}}>Open Positions</h3><br />
                            <Accordion>
                                <AccordionItem col-sm-12 col-md-12 col-xs-12 style={{color: 'white',marginBottom: '3%'}} >
                                    <AccordionItemTitle style={{textAlign:'center'}}>
                                        <h4>Software Intern</h4> 
                                    </AccordionItemTitle>
                                    <AccordionItemBody>
                                    <div className="careerpanel" panel>
                                         <p> You will be working on various aspects of the app and the website. We are curently looking for Web Development and Machine Learning Interns<br />If you are interested then please submit your resume <a href="#">Here</a></p>
                                    </div>
                                    </AccordionItemBody>
                                </AccordionItem>
                                <AccordionItem col-sm-12 col-md-12 col-xs-12 style={{color: 'white'}}>
                                    <AccordionItemTitle style={{textAlign:'center'}}>
                                       <h4>Social Media Intern</h4>
                                    </AccordionItemTitle>
                                    <AccordionItemBody>
                                    <div className="careerpanel" panel style={{color: 'white',marginLeft: '0%'}}>
                                        <p>You will write blog posts, tweet, use social media or any other approach to spread awareness of our app. You will talk to customers and understand them. You will relay this information to the marketing and engineering teams. <a href="#">Know More</a>
                                            <br />
                                            Cellfish app is in stealth mode. To know more about the app
                                            Download using this single link http://tinkerix.com/download (Use this referral code 341)
                                            Register for internship <a href="#">here</a>
                                        </p>
                                    </div>
                                    </AccordionItemBody>
                                </AccordionItem>
                            </Accordion>
                            <div col-sm-3></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



export default Career