
import rechargeGif from './images/recharge-min.gif'
import cutBillGif from './images/Cut-your-Mobile-bill-min.gif'
import saveMoreGif from './images/Save-more-for-your-family-min.gif'
import earnMoreGif from './images/Earn-more-through-referal-min.gif'

export default [
    {
        name:"usage",
        description: "Help others #GetCellFish, Earn Rs. 10,000",
        image: rechargeGif,
        backgroundColor : '#499EC3'

    },
    {
        name:"saving",
        description: "Cut Your mobile bill in half",
        image: cutBillGif,
        backgroundColor : '#499EC3'
    },
    {   
        name:"manage",
        description: "Help family save a bundle",
        image: saveMoreGif,
        backgroundColor : '#499EC3'
    },
    {
        name:"refer",
        description: "Refer friends to earn every month",
        image: earnMoreGif,
        backgroundColor : '#499EC3'
    }
]
  
