import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import About from '../about/About';
import FacebookIcon from '../../images/Facebook.png'
import TwitterIcon from '../../images/Twitter.png'
import YoutubeIcon from '../../images/Youtube.png'
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion';

 

class Footer2 extends Component{
    render(){
        return(
            <div class="Footer">
            <div className="homeFooter hidden-xs col-sm-12 col-md-12 col-lg-12">
        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
        <div className="footerWrapper col-xs-11 col-sm-11 col-lg-11 col-md-11">
          <div className="col-sm-2 col-xs-12 col-md-2 col-lg-2">
            <div className="footer-col-head">Support</div>
            <div id="git" className="footer-col">
              <ul className="unstyled">
                <li className="footer-col-item"><Link to="/faq">FAQ</Link></li>
                <li className="footer-col-item"><Link to="/contactus">Contact Us</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-sm-2 col-xs-12 col-md-2 col-lg-2">
            <div className="footer-col-head">Legal</div>
            <div className="footer-col">
              <ul className="unstyled">
                <li className="footer-col-item"><Link to="/tnc">Terms and Conditions</Link></li>
                <li className="footer-col-item"><Link to="/privacy">Privacy</Link></li>
                <li className="footer-col-item"><Link to="/refund">Refund</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-sm-2 col-xs-12 col-md-2 col-lg-2">
            <div className="footer-col-head">Our Company</div>
            <div id="serv" className="footer-col">
              <ul className="unstyled">
                <li className="footer-col-item"><Link to="/about">About Us</Link></li>
                <li className="footer-col-item"><Link to="/career">Career</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-sm-2 col-xs-12 col-md-2 col-lg-2">
            <div className="footer-col-head">Resources</div>
            <div id="last" className="footer-col">
              <ul className="unstyled">
                <li className="footer-col-item"><a href="./blog.html">Blog</a></li>
              </ul>
            </div>
          </div>
          <div className="col-sm-4 hidden-xs col-md-3 col-lg-3">
            <iframe id={1} src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FTinkerixTechnology&tabs=timeline&width=271&height=300&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId" width="100%" height={300} style={{overflow: 'hidden'}} scrolling="no" frameBorder={0} allowTransparency="true" />
          </div>            </div>
      </div>

      <div className="col-xs-12 visible-xs">
      <Accordion>
        <AccordionItem>
           <AccordionItemTitle>
           <h4>Support</h4>
           </AccordionItemTitle>
           <AccordionItemBody>
           <div className="panel">
             <ul className="unstyled">
             <li className="footer-col-item"><Link to="/faq"> FAQ </Link></li>
                 <li className="footer-col-item"><Link to="/contactus">Contact Us</Link></li>
              </ul>
            </div>
           </AccordionItemBody>
        </AccordionItem>
      </Accordion>
      <Accordion>
        <AccordionItem>
          <AccordionItemTitle>
          <h4>Legal</h4>
          </AccordionItemTitle>
          <AccordionItemBody>
          <div >
            <ul className="unstyled">
            <li className="footer-col-item"><Link to="/tnc">Terms and Conditions</Link></li>
            <li className="footer-col-item"><Link to="/privacy">Privacy</Link></li>
              <li className="footer-col-item"><a href="./refunds.html">Refund</a></li>
            </ul>
           </div>
          </AccordionItemBody>
        </AccordionItem>
      </Accordion>
      <Accordion>
        <AccordionItem>
          <AccordionItemTitle>
            <h4>Our Company</h4>
          </AccordionItemTitle>
          <AccordionItemBody>
          <div >
           <ul className="unstyled">
             <li className="footer-col-item"><Link to="/about">About Us</Link></li>
             <li className="footer-col-item"><Link to="/career">Career</Link></li>
            </ul>
         </div>
          </AccordionItemBody>
        </AccordionItem>
      </Accordion>
      <Accordion>
          <AccordionItem>
            <AccordionItemTitle>
             <h4>Resources</h4> 
            </AccordionItemTitle>
            <AccordionItemBody>
            <div>
              <ul className="unstyled">
                <li className="footer-col-item"><a href="./blog.html">Blog</a></li>
              </ul>
            </div>
            </AccordionItemBody>
          </AccordionItem>
      </Accordion>
      </div>
       <div className="footerCopyright text-center" style={{marginTop:'5%'}}>
            <h4 style={{display: 'inline'}}>Connect With Us:</h4>
            <img style={{display: 'inline',width: '50px',marginTop: '5px',marginLeft:'5px'}} src={TwitterIcon} alt="twitter"/>
            <img style={{display: 'inline',width: '50px',marginTop: '5px',marginLeft: '5px'}} src={FacebookIcon} alt="facebook"/>
            <img style={{display: 'inline',width: '50px',marginTop: '5px',marginLeft: '5px'}} src={YoutubeIcon} alt="youtube"/>
        </div>
        <div className="footerCopyright text-center" style={{marginTop:'5%'}}>
         Copyright Tinkerix Technology Pvt Ltd</div>
      </div>

        );
    }
}

export default Footer2