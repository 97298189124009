import React from 'react'
import './appview2.css'

export default function Tab(props) {
  const {getActiveTab, name} = props
  return (
    <div onClick={() => getActiveTab(name)}>
      <a href="#usage" id="usageLink" className="featuresLink" aria-controls="usage" role="tab" data-toggle="tab">
       <div className="_comp--image-title features-tab">
         <div className="_comp--image">
          {props.children}
          </div>
         <div className="_comp--title txt-upper txt-small">{name}</div>
       </div>
     </a>
    </div>
  )
}
