import React, { Component } from 'react';
import UsageContainer from './usageBySim';
import axios from 'axios';
import { getApiHost } from '../../constants/routes';


class UsageComponent extends Component{
    constructor(props){
        super(props);
        this.state={
            authUser: JSON.parse(localStorage.getItem('authUser')),
        }
    }

    fetchSimUsage(){
        
        const token = this.state.authUser.idToken;
        const headers = {
            "Authorization" : `Bearer ${token}`,
            'Content-Type': 'application/json'
        };  
        
        const findUsageBySimid = () => {
            const sim = this.props.sim;
            const simId = sim.id;
            console.log("simid = "+simId);
            const url = getApiHost(this.state.authUser.email)+"/v1/user/"+simId+"/usage";
            const params = {
                simid : simId
            };
            axios.get(url,{headers, params})
            .then(function (response){
              this.setState({
                usageData : response.data,
                isUsageLoaded : true,
              })
            }.bind(this))
            .catch(function(error){
              this.setState({
                errorInUsage : error,
              })
              alert("Something went wrong in getting Usage");
              console.log("error occured",error);
            }.bind(this));
          }
          findUsageBySimid();
    }

    componentDidMount() {
        this.fetchSimUsage()
    }

    componentDidUpdate(prevProps) {
        if(prevProps.sim.id !== this.props.sim.id) {
            this.fetchSimUsage()

        }
    }

    render(){
        return(
            <div style={{marginRight:10}}>
                { this.state && this.state.usageData &&
                    <UsageContainer usage={this.state.usageData} simName = {this.props.sim.nickname}/>
                } 
            </div>
        );
    }
}

export default UsageComponent;
