import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import Card from '@material-ui/core/Card';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Logo } from '../SignIn';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const PasswordForgetPage = () => (
  <div>
    <h1>PasswordForget</h1>
    <PasswordForgetForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};
const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    height:'80%',
    width: '60%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#673AB7',
    fontSize: 13,
    color: 'white',
  },
  card: {
    maxWidth: '40vw',
    height : '60vh',
    alignContent: 'center',
    marginBottom: '5%',
    margin: '0 auto',
    marginTop : '5%'
  },
}));

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <PasswordForget email={this.state.email} onChange = {this.onChange} onSubmit={this.onSubmit} error={error}
      />
    );
  }
}

function PasswordForget(props){
  const classes = useStyles();
  return(
    <Card component="main" className={classes.card}>
      <CssBaseline />
        <div className={classes.paper}>
          <Logo/>
          <Typography component="h1" variant="h5">
            Forgot Password?
          </Typography>
          <form className={classes.form} noValidate onSubmit={props.onSubmit}> 
            <TextField
              name="email"
              variant="outlined"
              autoComplete="email"
              required
              fullWidth
              value={props.email}
              onChange={props.onChange}
              type="text"
              placeholder = "Email Address"
            />
            <Button
              type="submit"
              fullwidth
              variant="contained"
              className={classes.submit}
            > Reset My Password</Button>
            {props.error && <p>{props.error.message}</p>}
          </form>
        </div>
    </Card>
  );
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
