import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import img from './images/ic_sim_card.xml';
import SimCard from './simCard';


const styles = theme => ({
  root: {
    width:'45vw',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    marginLeft: '5%',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '65%',
    height: '100vh',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  titleBar:{
    backgroundColor:'#673AB7',
  },
  checkbox:{
    marginRight : 10,
  }
});

 
function TitlebarGridList(props) {
  const { classes, onClick } = props;
  return (
    <div className={classes.root}>
      <GridList cellHeight={300} className={classes.gridList}>
        <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
          <ListSubheader component="div"><b style={{fontSize:'20px'}}>SIM LIST</b></ListSubheader>
        </GridListTile>
        {props.simData.map((sim, i) => (
          <GridListTile >
            <SimCard simData={sim} onClick={() => onClick(i)}/>
            <GridListTileBar
              className={classes.titleBar}
              title={sim.provider}
              actionIcon={
                <div className= {classes.checkbox}>
                <input
                  type='checkbox'
                  id='customSwitchesChecked'
                  onChange={() => props.onCheckBoxClicked(i,!sim.isEnabled)}
                  checked = {sim.isEnabled}
                />
                </div>
              }
            />
          </GridListTile>
        ))} 
      </GridList>
    </div>
  );
}



TitlebarGridList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TitlebarGridList);
