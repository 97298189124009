import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { MainListItems, secondaryListItems } from './listItems';
import { getApiHost } from '../../constants/routes';
// import Chart from './Chart';
// import Deposits from './Deposits';
// import Orders from './Orders';
import ChartClass from './chart';
import axios from 'axios';
import FishCash from './fishcash';
import SimsClass from './sims';



const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        overflow: 'hidden'
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 15,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        zIndex: 0,
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '80vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

export default class DashboardClass extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            authUser : JSON.parse(localStorage.getItem('authUser')),
            activeTab: "1",
        }
    }

    componentDidMount(){
        const token = this.state.authUser.idToken;
        const headers = {
            "Authorization": `Bearer ${token}`,
            'Content-Type': 'application/json'
          };
          const plotUsage = () => {
            const url = getApiHost(this.state.authUser.email)+'/v1/user/'+this.props.primarySimid+'/datalogsGrouped';
            const params = {
              email: this.state.authUser.email
            };
            axios.get(url, { headers, params })
              .then((response) => {
                this.setState({
                  dataForPlot: response.data,
                  isUserLoaded: true,
                })
                if (response.data.extras != null && response.data.extras.LowCashNotificationRaised !== null && response.data.extras.LowCashNotificationRaised === "true") {
                  this.setState({
                    lowCashNotificationRaised: true,
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  errorInUserDetails: error,
                })
                alert("No User Present with this email id");
                console.log("error occured", error);
              });
          }
          plotUsage();
    }


    render() {
        return <Dashboard activeTab={this.state.activeTab} dataForPlot={this.state.dataForPlot} fishcash = {this.props.fishcash} onTabChange={(tabKey) => this.setState({ activeTab: tabKey })} 
        lowCashNotificationRaised={this.props.lowCashNotificationRaised} sims={this.props.sims} simNickName={this.props.simNickName}
        ></Dashboard>
    }
}

export function Dashboard(props) {
    const classes = useStyles();
    const [close, setClose] = React.useState(true);
    const handleDrawerOpen = () => {
        setClose(false);
    };
    const handleDrawerClose = () => {
        setClose(true);
    };
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, close && classes.drawerPaperClose),
                }}
                close={close}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton  fontSize="large" onClick={handleDrawerClose} className={clsx(close && classes.menuButtonHidden)}>
                        <ChevronLeftIcon />
                    </IconButton>
                    <IconButton fontSize="large" onClick={handleDrawerOpen} className={clsx(classes.menuButton,!close && classes.menuButtonHidden)}>
                        <MenuIcon ></MenuIcon>
                    </IconButton>
                    
                </div>
                <Divider />
                <List><MainListItems onTabChange={props.onTabChange}/></List>
            </Drawer>
            {props.activeTab === "1" && <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        {/* Chart */}
                        <Grid item xs={12} md={8} lg={9}>
                            <Paper className={fixedHeightPaper}>
                               {props.dataForPlot && <ChartClass simNickName={props.simNickName} dataForPlot={props.dataForPlot}/>}
                            </Paper>
                        </Grid>
                        {/* Recent Deposits */}
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper className={fixedHeightPaper}>
                                <FishCash fishcash={props.fishcash}/>
                            </Paper>
                        </Grid>
                        {/* Recent Orders */}
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                {/* <Orders /> */}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </main>}
            {props.activeTab === "2" && <SimsClass sims={props.sims} lowCashNotificationRaised={props.lowCashNotificationRaised}/>}
        </div>
    );
}