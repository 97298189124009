import React,{Component} from 'react'

class Privacy extends Component{
    render() {
      return (
        <div className=" col-xs-12 col-sm-12 col-md-12">
        <div className="termsHeading col-xs-12 col-sm-12 col-md-12 text-center">
          <h2>CellFish Privacy Policy</h2>
        </div>
        <div className="termsTextWrapper col-xs-12 col-sm-12 col-md-12">
          <div className="col-xs-1 col-md-2 col-sm-2" />
          <div className="termsText col-xs-10 col-md-8 com-sm-8">
            <p className="MsoNormal" style={{lineHeight: '15.0pt'}}><span style={{fontFamily: '"Crimson Text"', msoFareastFontFamily: '"Times New Roman"', color: '#333333'}}>Thank you for using <span className="SpellE">CellFish</span>! We wrote this policy to help you understand what
                information we collect, how we use it and what choices you have. We welcome
                your questions and comments on this policy.<br style={{msoSpecialCharacter: 'line-break'}} />
                {/*[if !supportLineBreakNewLine]*/}<br style={{msoSpecialCharacter: 'line-break'}} />
                {/*[endif]*/}</span></p>
            <h3><span style={{msoFareastFontFamily: '"Times New Roman"', color: '#333333'}}>What
                information do we collect?</span></h3>
            <p className="MsoNormal" style={{lineHeight: '15.0pt'}}><span style={{fontFamily: '"Crimson Text"', msoFareastFontFamily: '"Times New Roman"', color: '#333333'}}>We collect information
                in a few different ways:</span></p>
            <div data-section-style={5}>
              <ul type="disc">
                <li className="MsoNormal" style={{color: '#333333', msoMarginTopAlt: 'auto', marginBottom: '4.8pt', lineHeight: '15.0pt', msoList: 'l0 level1 lfo2', tabStops: 'list 36.0pt'}}><b><span style={{fontFamily: '"Crimson Text"', msoFareastFontFamily: '"Times New Roman"'}}>When
                      you give it to us or give us <span className="GramE">permission &nbsp;-</span>&nbsp;</span></b><span style={{fontFamily: '"Crimson Text"', msoFareastFontFamily: '"Times New Roman"'}}>To
                    use our product you voluntarily give us certain information. This can
                    include your email address, phone number, location, call logs and message
                    logs and your contacts. You may also give us permission to access your
                    information in other services <span className="SpellE">eg</span>. Facebook,
                    Twitter or Google. </span></li>
                <li className="MsoNormal" style={{color: '#333333', msoMarginTopAlt: 'auto', marginBottom: '4.8pt', lineHeight: '15.0pt', msoList: 'l0 level1 lfo2', tabStops: 'list 36.0pt'}} id="ECaACA1DwKT"><b><span style={{fontFamily: '"Crimson Text"', msoFareastFontFamily: '"Times New Roman"'}}>Technical information when you use our product </span></b><span style={{fontFamily: '"Crimson Text"', msoFareastFontFamily: '"Times New Roman"'}}>-&nbsp;These
                    days whenever you use a website, mobile application or other internet
                    services, there's certain information that almost always gets created and
                    recorded automatically. The same is true of our products. This includes
                    device information, applications you use on your device and other log
                    data. </span></li>
              </ul>
            </div>
            <h3 id="ECaACACo9e0"><span style={{msoFareastFontFamily: '"Times New Roman"', color: '#333333'}}>How do we use the information we collect?</span></h3>
            <p className="MsoNormal" style={{lineHeight: '15.0pt'}}><span style={{fontFamily: '"Crimson Text"', msoFareastFontFamily: '"Times New Roman"', color: '#333333'}}>We use the information
                we collect to provide our products to you, make them better, develop new
                products and protect our users. For example we collect call and message logs to
                determine how much you use your phone and this helps us manage your mobile
                account efficiently for you.<br style={{msoSpecialCharacter: 'line-break'}} />
                {/*[if !supportLineBreakNewLine]*/}<br style={{msoSpecialCharacter: 'line-break'}} />
                {/*[endif]*/}</span></p>
            <h3><span style={{msoFareastFontFamily: '"Times New Roman"', color: '#333333'}}>What
                choices do you have about your information?</span></h3>
            <p className="MsoNormal" style={{lineHeight: '15.0pt'}}><span style={{fontFamily: '"Crimson Text"', msoFareastFontFamily: '"Times New Roman"', color: '#333333'}}>Our product requires
                personal information to function. You can choose to unlink your account by
                uninstalling our product. Please write to us (see below) to remove all your
                information from our server.<br style={{msoSpecialCharacter: 'line-break'}} />
                {/*[if !supportLineBreakNewLine]*/}<br style={{msoSpecialCharacter: 'line-break'}} />
                {/*[endif]*/}</span></p>
            <h3><span style={{msoFareastFontFamily: '"Times New Roman"', color: '#333333'}}>How
                and when do we share information?</span></h3>
            <p className="MsoNormal" style={{lineHeight: '15.0pt'}}><span style={{fontFamily: '"Crimson Text"', msoFareastFontFamily: '"Times New Roman"', color: '#333333'}}>We never share your
                information with anybody except for the following cases:</span></p>
            <div data-section-style={5}>
              <ul type="disc">
                <li className="MsoNormal" style={{color: '#333333', msoMarginTopAlt: 'auto', marginBottom: '4.8pt', lineHeight: '15.0pt', msoList: 'l1 level1 lfo4', tabStops: 'list 36.0pt'}}><span style={{fontFamily: '"Crimson Text"', msoFareastFontFamily: '"Times New Roman"'}}>When
                    you buy something in our product we pass on information to the payment
                    provider and the merchant. </span></li>
                <li className="MsoNormal" style={{color: '#333333', msoMarginTopAlt: 'auto', marginBottom: '4.8pt', lineHeight: '15.0pt', msoList: 'l1 level1 lfo4', tabStops: 'list 36.0pt'}} id="ECaACAlf5Dx"><span style={{fontFamily: '"Crimson Text"', msoFareastFontFamily: '"Times New Roman"'}}>If we believe that disclosure is reasonably necessary
                    to comply with a law or regulation to protect the safety, rights, or
                    property of the public, any person, or <span className="SpellE">CellFish</span>;
                    or to detect, prevent, or otherwise address fraud, security or technical
                    issues.</span></li>
                <li className="MsoNormal" style={{color: '#333333', msoMarginTopAlt: 'auto', marginBottom: '4.8pt', lineHeight: '15.0pt', msoList: 'l1 level1 lfo4', tabStops: 'list 36.0pt'}} id="ECaACAdvdre"><span style={{fontFamily: '"Crimson Text"', msoFareastFontFamily: '"Times New Roman"'}}>We may engage in a merger, acquisition, bankruptcy,
                    dissolution, reorganization, or similar transaction or proceeding that
                    involves the transfer of the information described in this Policy. </span></li>
              </ul>
            </div>
            <p className="MsoNormal" style={{lineHeight: '15.0pt'}}><span style={{fontFamily: '"Crimson Text"', msoFareastFontFamily: '"Times New Roman"', color: '#333333'}}>We may also share
                aggregated or non-personally identifiable information with our partners,
                advertisers, or others.<br style={{msoSpecialCharacter: 'line-break'}} />
                {/*[if !supportLineBreakNewLine]*/}<br style={{msoSpecialCharacter: 'line-break'}} />
                {/*[endif]*/}</span></p>
            <h3><span style={{msoFareastFontFamily: '"Times New Roman"', color: '#333333'}}>How
                to contact us regarding privacy issues?</span></h3>
            <p className="MsoNormal" style={{marginBottom: '12.0pt', lineHeight: '15.0pt'}}><span style={{fontFamily: '"Crimson Text"', msoFareastFontFamily: '"Times New Roman"', color: '#333333'}}>Please contact us at <b>support@tinkerix.com</b> for any
                concerns, issues or questions.<br />
                <br />
                <i>We may change this policy from time to time, and if we do we’ll post any
                  changes on this page. If you continue to use <span className="SpellE">CellFish</span>
                  after those changes are in effect, you agree to the revised policy. If the
                  changes are significant, we may provide more prominent notice or get your
                  consent as required by law.</i><br />
                <br />
                <b><i>Effective: Jan 31, 2017</i></b><br style={{msoSpecialCharacter: 'line-break'}} />
                {/*[if !supportLineBreakNewLine]*/}<br style={{msoSpecialCharacter: 'line-break'}} />
                {/*[endif]*/}</span></p>
          </div>
          <div className="col-xs-1 col-md-2 col-sm-2" />
        </div>
        <div className="termsSeparator col-xs-12 col-sm-12 col-md-12">&nbsp;</div>.
      </div>
      )
    }
}
export default Privacy;