import React, { Component } from 'react';
import '../../css/Testimonial.css'
import Swiper from 'react-id-swiper';
import navdeepImg from '../../images/testimonial/navadeep-min.png'
import bharatImg from '../../images/testimonial/bharat-min.jpg'
import charanImg from '../../images/testimonial/charan-min.jpg'
import dineshImg from '../../images/testimonial/dinesh-min.jpg'
import saiImg from '../../images/testimonial/sai-min.jpg'
import sreekarImg from '../../images/testimonial/sreekar-min.jpg'
import ushaImg from '../../images/testimonial/usha-min.jpg'
import mukeshImg from '../../images/testimonial/mukesh-min.jpg'


const Testimonials = [
  {
    name : "Navadeep Raja",
    desc : "App is one of its kind at least for India. Coming to pros of app by the end of day it just stops leakage of your savings on never understood packages of prepaid. Though basically app does most things regarding the prepaid market for me 2 standout features are the Self  Recharging feature which puts me always on safe side from call drops due to insufficient Balance and for Providing bills like in postpaid so I know where I can reduce my usage.",
    img : navdeepImg 
  },
  {
    name : "Usha Erramada",
    desc : "The auto recharge feature is great. The app selects the best packs according to our usage and saves our money. Interesting landing pages.",
    img : ushaImg
  },
  {
    name : "Bharat Sunny",
    desc : "Most awaited app where I'm waiting from past.finally I have got the best app where I can save my money :) i dnt ask my parents to always recharge for my mobile. I can get call cost for each and every call so I know what I have used and mostly the best feature automatic recharge it's amazing.once I have loaded cash it directly recharge's the mobile.Now I'm free from the mobile bills and also not only me but also I can manage my parents mobiles too,thanks finally satisified",
    img : bharatImg
  },
  {
    name : "Sai Kumar",
    desc : "Awesome app! I did not have to worry about frequently subscribing to a new SMS or Talktime Packs.The app took care of it.Also helped me in recharging the mobile phones of my family automatically without any hassle.The user interface is made user-friendly",
    img : saiImg
  },
  {
    name : "Sreekar Saineni",
    desc : "Wonderful application with excellent user experience which manages all my Billings with the mobile network. It also has awesome feature to auto recharge me and my beloved ones with excellent optimisation techniques.Love to be part of Cellfish family.",
    img : sreekarImg
  },
  {
    name : "Sai Charan",
    desc : "Cellfish is one of the best app I have ever seen where  once I load the cash it is automatically recharging where there is no worry for recharge at the same time I'm able to manage my family recharges its look very smart and giving best packs according to the usage. \n Thank you Cellfish  APP",
    img : charanImg
  },
  {
    name : "Dinesh Reddy",
    desc : "My Experience with Cellfish is somewhere between better and best. It made me completely free from hectic task of recharging and Choosing the right offer.",
    img : dineshImg
  },
  {
    name : "Mukesh Baba",
    desc : "I found cellfish app is one best apps I've come across the apps which are used to spend our recharge wiser and manages our recharges and bills wisely. And also I like the thought that was introduced about automatically recharing our dear ones' mobiles and highly impressed by the tracking system used.",
    img : mukeshImg
  }
];

class Testimonial extends Component{
  constructor(props) {
    super(props)
    this.goNext = this.goNext.bind(this)
    this.goPrev = this.goPrev.bind(this)
    this.swiper = null
  }
 
  goNext() {
    if (this.swiper) this.swiper.slideNext()
  }
 
  goPrev() {
    if (this.swiper) this.swiper.slidePrev()
  }
  
  render(){
    const params = {
      slidesPerGroup: 1,
      spaceBetween: 30,
      centeredSlides: true,
      direction: 'horizontal',
      autoplay: {
        delay: 2500,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    };
    {/* // TODO: check wether this can be possible */}
    var createTestimonialList = () => {
        let swiperElements = [];
        for(let i=0;i<Testimonials.length();i++){
          swiperElements.push(
            <div><SingleTestimonial user = {Testimonials[i]}/></div>
          )
        }
        return swiperElements;
    }
    return(
      <div className="testimonial-container">
         <Swiper {...params}>
          <div><SingleTestimonial user = {Testimonials[0]}/></div>
          <div><SingleTestimonial user = {Testimonials[1]}/></div>
          <div><SingleTestimonial user = {Testimonials[2]}/></div>
          <div><SingleTestimonial user = {Testimonials[3]}/></div>
          <div><SingleTestimonial user = {Testimonials[4]}/></div>
          <div><SingleTestimonial user = {Testimonials[5]}/></div>
          <div><SingleTestimonial user = {Testimonials[6]}/></div>
          <div><SingleTestimonial user = {Testimonials[7]}/></div>
         </Swiper>
      </div>  
    );
  }
  
}

class SingleTestimonial extends Component{

  render(){
    return(
      <div className="item col-sm-10 col-xs-12 col-lg-10 col-md-10">
        <div className="userImage col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <center>
            <img src={this.props.user.img} alt="Navadeep reviews about cellfish" /></center>
        </div>
        <div className="userText text-center col-xs-12 col-sm-12 col-md-8 col-lg-8">
          <p className="testimonial-text" id="navadeep_testimonial_text" style={{color: 'white'}}>
            {this.props.user.desc}
          </p>
          <p className="testimonial-text-user"><strong>{this.props.user.name}</strong></p>
        </div>
      </div>
    );
  }
}



export default Testimonial;