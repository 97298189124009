import React, { Component } from 'react';
import './appview1.css'
import Bubble from './Bubble';
import bubbleInfo from './bubbleInfo';
import phoneFrame from '../../../images/phonescreenfinal.png'

import usageIcon from '../../../images/features/usage.svg'
import savingsIcon from '../../../images/features/savings.svg'
import manageIcon from '../../../images/features/settings.svg'
import billIcon from '../../../images/features/invoice.svg'
import PhoneScreen from '../PhoneFrame/PhoneScreen';


let index = 0;

class AppView1 extends Component{
  state={
    activeBubble: 'usage'
  }

  // getActiveBubble = (bubbleName) => {
  //   this.setState({activeBubble: bubbleName});
  // }

  // componentDidMount(){
  //   this.setTimer();
  // }
   setTimer(){
    index++;
    if(index>3){
        index = 0;
    }
    let name = bubbleInfo[index].name;
    return name;
  }

  componentDidMount() {
    console.log(this.state+ " "+ this.setTimer());
    this.interval = setInterval(() => this.setState({ activeBubble: this.setTimer()}), 4000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render(){
    const {activeBubble} = this.state
  
    return(
      <div className="appview1-container ">
        <div className="infoContainer hidden-xs">
          <div id = "phoneScreen1" classNaxme="_container__mobile-1--outer is-table full-wh">
            <div className="mobile-1--center v-center">
              <div className="mobile-1--content col-sm-12  col-md-12 ">
                {/* <div className="hidden-xs col-sm-12 col-xs-12 col-lg-12 col-md-12"> */}
                  <div className="botTextContainer">
                    <BubbleDescription activeBubble={activeBubble} style={{marginBottom:'20%'}}/>
                     <div className="bubbleContainer col-sm-12 col-xs-12 col-md-12 col-lg-12">
                       <Bubbles getActiveBubble={this.getActiveBubble} />
                     </div>
                  </div>
                {/* </div> */}
                <div  className = "hidden col-xs-12 text-center">
                  <img src = {phoneFrame} width="260px" alt="frame"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="phoneScreenContainer hidden-xs" >
                  <div style={{marginTop:'5%',marginLeft:'5%'}}>
                    <img src={phoneFrame} alt="Phone usage" className="phone-frame-image" />
                    {
                      bubbleInfo.map(obj => (
                        obj.name === activeBubble && 
                        <img src={obj.image} alt="In Frame" className="image-in-frame"/>
                      ))
                    }
                    {/* <img src={obj.image} alt="In Frame" className="image-in-frame"/> */}
                  </div>
                
          </div>

          <div id="mobileDisplayContainer" className="visible-xs">
        <div className="col-xs-12 phoneSeparator" />
        <div className="phone-text-wrapper">
          <ul id="phoneTextList">
            <li> <div className="text-center phone-text noDisplay">
                <p className="heading heading1">Understand your Usage</p>
                <p className="sub-heading">View accurate monthly statistics for all types of calls, SMS and data usage on your phone.</p>
              </div></li>
            <li> <div className="text-center phone-text noDisplay">
                <p className="heading heading2">Cut Your Bills in half</p>
                <p className="sub-heading">Buy the right data plan and call/sms rate cutters appropriate for your usage. Even better, Cellfish will automatically manage them.</p>
              </div></li>
            <li> <div className="text-center phone-text noDisplay">
                <p className="heading heading3">Save for your family</p>
                <p className="sub-heading">Automatically recharge Sims of your loved ones. Save their monthly bill too just like yours. Get notified when they need a recharge.</p>
              </div></li>
            <li> <div className="text-center phone-text">
                <p className="heading heading4">Referrals every month</p>
                <p className="sub-heading">Get onboard with our unique monthly referral system and keep earning on a monthly basis</p>
              </div></li>
          </ul>
        </div>
        <div className="col-xs-12 text-center">
          <img src={phoneFrame} width="260px" />
        </div>
        <div className="col-xs-12 text-center gif" style={{position: 'relative', bottom: '464px', right: '7px', height: '380px', overflow: 'hidden'}}>
          <div className="gif-wrapper" style={{height: '380px'}}>
            <ul id="gifList">
              {/* <li className="gif" id="gif1"> <img id="rechargegif" width="212px" src="./images/gif/recharge-min.gif" alt="Self recharging robot" /></li>
              <li className="gif" id="gif2"> <img id="savegif" width="212px" src="./images/gif/Cut-your-Mobile-bill-min.gif" alt="Cut your mobile bills in half" /></li>
              <li className="gif" id="gif3"> <img id="familygif" width="212px" src="./images/gif/Save-more-for-your-family-min.gif" alt="Save money for your family" /></li>
              <li className="gif" id="gif4"> <img id="refergif" width="212px" src="./images/gif/Earn-more-through-referal-min.gif" alt="Earn more through referals" /></li> */}
            </ul>
          </div>
        </div>
      </div>
        </div>
    );
  }
}
class NewAppView1 extends Component{
  render(){
    return(
      <div className="_container _container__mobile-1">
        <div className="backstrech__wrapper">
          <div className="backstrech__image" />
        </div>
        {/* App Screens */}
        <div className="_container _container-appscreens hidden-xs">
          <div className="container full-h">
            <div className="row full-h">
              <div className="appscreens--col col-sm-3 col-sm-offset-8 is-relative full-h">
                <div className="appscreens-frame">
                  <div className="appscreens--phone">
                    <div className="appscreens--phone-2nd-fold cellfish">
                      <ul className="ui-screen-wrapper ui-features">
                        <li className="ui-screen ui-feature" id="ui-feature-2"><img src="images/cellscreens/recommendation.jpeg" alt="prepaid phone plans" /></li>
                        <li className="ui-screen ui-feature" id="ui-feature-3"><img src="images/cellscreens/simmanagescreen.png" alt="save for your family" /></li>
                        <li className="ui-screen ui-feature" id="ui-feature-4"><img src="images/cellscreens/bill.jpeg" alt="prepaid phone bill" /></li>
                        <li id="ui-feature-1" className="ui-screen ui-feature"><img src="images/cellscreens/usagescreen.png" alt="Know your usage" /></li>
                      </ul>
                    </div>
                    <div className="appscreens--phone-1st-fold cellfish">
                      <ul className="ui-screen-wrapper ui-slider">
                        <li className="ui-screen ui-slide">
                          <img src="./images/gif/recharge-min.gif" alt="Self recharging robot" />
                        </li>
                        <li className="ui-screen ui-slide">
                          <img src="images/gif/Cut-your-Mobile-bill-min.gif?32" alt="Cut your mobile bills in half" />
                        </li>
                        <li className="ui-screen ui-slide">
                          <img src="images/gif/Save-more-for-your-family-min.gif?23" alt="Save Money for your family" />
                        </li>
                        <li className="ui-screen ui-slide">
                          <img src="images/gif/Earn-more-through-referal-min.gif?23" alt="Earn Every Month Through Referrals" />
                        </li>
                      </ul>
                    </div>
                    <div className="appscreens--phoneFrame">
                      <div className="appscreens--phoneFrame__wrapper">
                        <img src="images/phonescreenfinal.png" alt="Smart Phone" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* App Screens */}
        <div className="container full-h">
          <div className="row full-h">
            <div id="phoneScreen1" className="_container__mobile-1--outer is-table full-wh">
              <div className="mobile-1--center v-center">
                <div className="mobile-1--content col-sm-7 col-sm-offset-0 col-md-8 col-md-offset-0">
                  <div className="hidden-xs col-sm-12 col-xs-12 col-lg-12 col-md-12">
                    <div className="botTextContainer">
                      <p className="botText phoneShow text-center" id="botText1">"Help others #GetCellFish, <b>Earn Rs. 10,000</b></p>
                      <p className="botText phoneHide text-center" id="botText2">Cut Your mobile <b>bill in half</b></p>
                      <p className="botText phoneHide  text-center" id="botText3">Help family <b>save a bundle</b></p>
                      <p className="botText  phoneHide text-center" id="botText4">Refer friends to <b>earn every month</b></p></div><br />
                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                      <div className="featuresText col-sm-3 col-lg-3 col-md-3 col-xs-3 text-center"><img id="usagePic" className="featurePic" height="60px" width="70px" src="images/features/usage.svg" /><br /><br />Self Recharging Robot</div>
                      <div className=" featuresText col-sm-3 col-lg-3 col-md-3 col-xs-3 text-center"><img id="savingsPic" className="featurePic" height="60px" width="70px" src="images/features/savings.svg" /><br /><br />Cuts your bills<br />in half</div>
                      <div className=" featuresText col-sm-3 col-lg-3 col-md-3 col-xs-3 text-center"><img id="settingsPic" className="featurePic" height="60px" width="70px" src="images/features/settings.svg" /><br /><br />Saves Money for<br />Your family</div>
                      <div className=" featuresText col-sm-3 col-lg-3 col-md-3 col-xs-3 text-center"><img id="invoicePic" className="featurePic" height="60px" width="70px" src="images/features/share.png" /><br /><br />Earns every Month through<br />referrals</div>
                      <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                        <br />
                        {/* <div class = "col-sm-6 col-md-6 col-lg-6 col-xs-6" >
              <input type  = "number" name = "phoneNumber"></div>
             <div class = "col-sm-6 col-md-6 col-lg-6 col-xs-6">
              <input type = "Submit" value = "Send Download Link via SMS" name = "linkButton"></div>*/}
                      </div>
                    </div>
                  </div>
                  <div id="mobileDisplayContainer" className="visible-xs">
                    <div className="col-xs-12 phoneSeparator" />
                    <div className="phone-text-wrapper">
                      <ul id="phoneTextList">
                        <li> <div className="text-center phone-text noDisplay">
                            <p className="heading heading1">Understand your Usage</p>
                            <p className="sub-heading">View accurate monthly statistics for all types of calls, SMS and data usage on your phone.</p>
                          </div></li>
                        <li> <div className="text-center phone-text noDisplay">
                            <p className="heading heading2">Cut Your Bills in half</p>
                            <p className="sub-heading">Buy the right data plan and call/sms rate cutters appropriate for your usage. Even better, Cellfish will automatically manage them.</p>
                          </div></li>
                        <li> <div className="text-center phone-text noDisplay">
                            <p className="heading heading3">Save for your family</p>
                            <p className="sub-heading">Automatically recharge Sims of your loved ones. Save their monthly bill too just like yours. Get notified when they need a recharge.</p>
                          </div></li>
                        <li> <div className="text-center phone-text noDisplay">
                            <p className="heading heading4">Referrals every month</p>
                            <p className="sub-heading">Get onboard with our unique monthly referral system and keep earning on a monthly basis</p>
                          </div></li>
                      </ul>
                    </div>
                    <div className="col-xs-12 text-center">
                      <img src={phoneFrame} alt="frame" width="260px" />
                    </div>
                    <div className="col-xs-12 text-center gif" style={{position: 'relative', bottom: '464px', right: '7px', height: '380px', overflow: 'hidden'}}>
                      <div className="gif-wrapper" style={{height: '380px'}}>
                        <ul id="gifList">
                          <li className="gif" id="gif1"> <img id="rechargegif" width="212px" src="./images/gif/recharge-min.gif" alt="Self recharging robot" /></li>
                          <li className="gif" id="gif2"> <img id="savegif" width="212px" src="./images/gif/Cut-your-Mobile-bill-min.gif" alt="Cut your mobile bills in half" /></li>
                          <li className="gif" id="gif3"> <img id="familygif" width="212px" src="./images/gif/Save-more-for-your-family-min.gif" alt="Save money for your family" /></li>
                          <li className="gif" id="gif4"> <img id="refergif" width="212px" src="./images/gif/Earn-more-through-referal-min.gif" alt="Earn more through referals" /></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div></div></div></div></div>

    );
  }
}
class BubbleDescription extends Component{
  render(){
    const {activeBubble} = this.props;
    return(
      <div>
        { 
        bubbleInfo.map(obj => (
          obj.name===activeBubble &&
          <div className="botTextContainer" >
             <p className="botText phoneShow text-center" id="botText1">{obj.description}</p>
          </div>
         ))
        }
      </div>
      );
  }
}

class Bubbles extends Component{
  render(){
    const {getActiveBubble} = this.props
    return(
      <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
        <Bubble getActiveBubble={getActiveBubble} name='usage'
          image = {usageIcon}
          title = {"Self recharging robot"}
          color = {(getActiveBubble===this.name ? '#499EC3' : 'white')}
        >
        </Bubble>
        <Bubble 
          getActiveBubble={getActiveBubble} name='saving'
          image = {savingsIcon}
          title = {"Cuts your bills"}
          color = {'#FFFFFF'}
        />
        <Bubble getActiveBubble={getActiveBubble} name='manage'
          image = {manageIcon}
          title = {"Saves Money for Your Family"}
          color = {'#FFFFFF'}
        />
        <Bubble getActiveBubble={getActiveBubble} name='refer'
          image = {billIcon}
          title = {"Earns every Month through referrals"}
          color = {'#FFFFFF'}
        />
      </div>
    )

  }
}

export default AppView1;