import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import { FavoriteIcon } from '@material-ui/icons/Favorite';
const styles = {
  card: {
    maxWidth: 300,
    height : 300,
    backgroundColor : '#F7F7F7',
  },
  media: {
    width: 100,
    height : 100
  },
  warning:{
    fontSize: 'large',
    color : 'red',
    marginTop: 15,
  },
  warningText:{
    fontSize: 'medium',
    marginBottom: 5,
    color: 'red',
    marginLeft: 5,
  }

  
};

function MediaCard(props) {
  const { classes } = props;
  return (
    <Card className={classes.card} onClick = {props.onClick}>
        <CardMedia
          className={classes.media}
          image={require("./images/sim_card.png")}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.simData.nickname}
          </Typography>
          
          <Typography variant="h6" component="h4">
            <span>Phone Number {props.simData.phoneNumber}</span><br></br>
            <span>Circle {props.simData.circle}</span>
          </Typography>
          {!props.simData.paidSim && <Typography>
            <span><WarningIcon className={classes.warning}/></span><span className={classes.warningText}>Waiting for cashload</span>
          </Typography>}
          
        </CardContent>
      
    </Card>
  );
}

MediaCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MediaCard);
