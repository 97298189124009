import React, { Component } from 'react';
import axios from 'axios';

import { compose } from 'recompose';

import { getApiHost } from '../../constants/routes';
import {
  withAuthorization,
  withEmailVerification,
} from '../Session';
import SimList from './simList';
import './index.css'
import UsageComponent from './usage'
import FishCash from './fishcash'
import { Grid } from '@material-ui/core';
import DashboardClass from '../dashboard/dashbaord';


class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authUser: JSON.parse(localStorage.getItem('authUser')),
      errorInUserDetails: null,
      errorInSims: null,
      lowCashNotificationRaised: false,
      primarySimIndex : 0
    }
  }


  componentWillMount() {
    const token = this.state.authUser.idToken;
    const headers = {
      "Authorization": `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const findUser = () => {
      const url = getApiHost(this.state.authUser.email)+"/v1/user/findUser";
      const params = {
        email: this.state.authUser.email
      };
      axios.get(url, { headers, params })
        .then((response) => {
          this.setState({
            userid: response.data.id,
            isUserLoaded: true,
          })
          if (response.data.extras != null && response.data.extras.LowCashNotificationRaised !== null && response.data.extras.LowCashNotificationRaised === "true") {
            this.setState({
              lowCashNotificationRaised: true,
            });
          }
          getAllSims();
          fetchFishCash();
        })
        .catch((error) => {
          this.setState({
            errorInUserDetails: error,
          })
          alert("No User Present with this email id");
          console.log("error occured", error);
        });
    }

    const getAllSims = () => {
      const url = getApiHost(this.state.authUser.email)+"/v1/user/getAllSims";
      const params = {
        userid: this.state.userid
      }
      axios.get(url, { headers, params })
        .then((response) => {
          this.setState({
            sims: this.setEnableStatusToSimList(response.data),
            isSimsLoaded: true,
            selectedSimIndex: 0,
           
          })
        })
        .catch((error) => {
          this.setState({
            errorInSims: error,
          })
          alert("Something went wrong in fetching Sims");
          console.log("error occured", error);
        });
    }

    const fetchFishCash = () => {
      const userid = this.state.userid;
      const url =  getApiHost(this.state.authUser.email)+"/v1/user/" + userid + "/CASH";
      axios.get(url, { headers })
        .then(function (response) {
          this.setState({
            fishcash: response.data,
          })

        }.bind(this))
        .catch(function (error) {
          alert("Something went wrong in getting Fishcash");
        });
    }
    findUser();
  }

  onSimClick = (i) => {
    this.setState({ selectedSimIndex: i })
  }

  onCloseNotificationClick = () => {
    this.setState({
      showLowCashNotification: false,
    })
  }

  setEnableStatusToSimList(simdata) {
    if (simdata != null || simdata !== undefined) {
      for (var j = 0; j < simdata.length; j++) {
        if(simdata[j].primary){
          this.setState({
            primarySimIndex : j,
          })
        }
        var dataList = simdata[j].autoBuyEnableDataList;
        if (dataList == null || dataList === undefined || dataList.length === 0)  {
          simdata[j].isEnabled = true;
        } else {
          var res = false;
          for (var i = 0; i < dataList.length; i++) {
            var currentTime = (new Date()).getTime();
            var isCurrentEntry = dataList[i].currentEnableEntry;
            var isValidDuration = (dataList[i].endTime > currentTime && dataList[i].startTime < currentTime);
            if (isCurrentEntry && isValidDuration) {
              res = true;
            }
          }
          simdata[j].isEnabled = res;
        }
      }
    }
    return simdata;
  }

  render() {
    return (
      // <div className='parent_container' style={{ marginTop: '5%' }}>
      //   {this.state.lowCashNotificationRaised && this.state.showLowCashNotification &&
      //     <div className="alert warning">
      //       <span className="closebtn" onClick={this.onCloseNotificationClick}>&times;</span>
      //       <strong>Warning!</strong> CellFish Balance is critically low. Load cash now to continue enjoying the seamless experience.
      //     </div>
      //   }

      //   <Grid container style={{ marginTop: '5%' }}>
      //     {this.state.sims && <Grid item md={5}>
      //       <SimList errorInSims={this.state.errorInSims} sims={this.state.sims} isSimsLoaded={this.state.isSimsLoaded} onClick={this.onSimClick} />
      //     </Grid>}
      //     <Grid item md={5}>
      //       <h2>Usage </h2>
      //       {/* <UsageContainer errorInSims = {this.state.errorInSims} sims={this.state.sims} isSimsLoaded={this.state.isSimsLoaded}/>         */}
      //       {this.state && this.state.sims &&
      //         <UsageComponent sim={this.state.sims[this.state.selectedSimIndex]} />
      //       }
      //     </Grid>
      //     <Grid item md={2}>
      //       <FishCash fishcash={"Cash : " + this.state.fishcash + " Rs"} />
      //     </Grid>
      //   </Grid>
        
      // </div>
      <div  style={{ marginTop: '4%' }}>
        {this.state.sims && <DashboardClass fishcash={this.state.fishcash} lowCashNotificationRaised={this.state.lowCashNotificationRaised} sims={this.state.sims} primarySimid={this.state.sims[this.state.primarySimIndex].id}
        simNickName ={this.state.sims[this.state.primarySimIndex].nickname} />}
      </div>
      
    );
  }

}

const condition = authUser => !!authUser;



export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(HomePage);
