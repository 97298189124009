import React,{Component} from 'react'
import './tnc.css'

class Tnc extends Component{
    render() {
        return (
          <div className="termsContainer col-xs-12 col-sm-12 col-md-12">
            <div className="termsHeading col-xs-12 col-sm-12 col-md-12 text-center">
              <h2 style={{color: 'black'}}>Terms and Conditions</h2>
            </div>
            <div className="termsTextWrapper col-xs-12 col-sm-12 col-md-12">
              <div className="col-xs-1 col-md-2 col-sm-2" />
              <div className="termsText col-xs-10 col-md-8 com-sm-8">
                Cellfish is a service created by Tinkerix Technologies Pvt Ltd. This page explains the terms by which you may use our online and/or mobile services, web site, and software provided on or in connection with the service. By accessing or using the service, you signify that you have read, understood, and agree to be bound by this Terms and Conditions agreement and to the collection and use of your information as set forth in the Privacy Policy (cell.fish/privacypolicy.html).<br />
                <h3 id="ECaACAJ3SaC">Registration</h3>
                By using the application you agree that you are at least eighteen (18) years of age. If you are under the age of eighteen (18) years, you agree that you and your parent or legal guardian have reviewed this agreement and further if required your parent/legal guardian shall perform or undertake such activities to enter into a legally binding agreement with Tinkerix. You agree that you are fully competent to enter into and to comply with the terms, conditions, obligations, representations, and warranties set forth in this agreement. You hereby represent that you have the right, authority, and capacity to enter into this agreement and can abide by all of the terms and conditions set forth therein.
                <br />
                <h3 id="ECaACAJ3SaC">Grant of License</h3>
                You hereby agree that all rights, title and interest in the application are owned by Tinkerix. Subject to your strict and full compliance with the agreement, Tinkerix hereby grants you a revocable, non-exclusive, non-transferable, non-sub-licensable, limited license, to download, install and use the application strictly in accordance with the terms of the agreement. 
                <br />
                <h3 id="ECaACAJ3SaC">Services</h3>
                Tinkerix shall provide services to you based on information crowd sourced, inputs from you and data obtained from your devices. These include recommendations for mobile plans, tips to save money and optimizations to reduce your cost. Tinkerix will make a best effort to make sure these services are available to you at all times. However as the services are provided over the Internet (wireless and mobile) the quality and availability are subject to factors outside Tinkerix's control.
                <br />
                <h3 id="ECaACAJ3SaC">Terms of Access</h3>
                The Cellfish application is a means to provide the services to you. Your access of the application signifies constent to receive these services. Tinkerix may in its sole discretion, and without any obligation or liability towards you restrict or expand the scope of services provided to you. From time to time Tinkerix may provide updates to the application. However Tinkerix shall be under no obligation to do so. All updates/upgrades provided by Tinkerix will be subject to the terms of this agreement and any amendments made to the agreement from time to time.
                <br />
                <h3 id="ECaACAJ3SaC">Restrictions</h3>
                You shall use the Application strictly in accordance with the agreement and shall not: (i) decompile, disassemble, reverse engineer or attempt to derive the source code of or in any manner decrypt the Application; (ii) make any modification, adaptation or improvement, enhancement, or derivative work from the Application; (iii) violate any applicable laws or regulations in connection with your access or use of the Application or the Plans; (iv) remove or obscure any proprietary notice (including any notice of copyright or trademark) forming a part of the Application or the Plans; (v) use the Application, Services or the Plans for any revenue generation endeavor, or any other purpose for which it is not designed or intended; (vi) install, use or permit the Application to exist on more than one Device at a time, other than by means of your separate downloads of the Application, each of which is subject to a separate license; (vii) make available the Application/Services/Plans ove r a network or other environment permitting access or use by multiple users at the same time or where it could be used by multiple devices at the same time, whether or not the same is for commercial use; (viii) use the Application for data mining, scraping, crawling, redirecting, or for any purpose not in accordance with the terms of the agreement; (ix) use the Application to attempt to interfere with the proper display of the Plans; (x) use the Application for undertaking any hacking activities like breaching or attempt to breach the security of another user or attempt to gain access to any other person's computer, software or data without the knowledge and consent of such person; (xi) use the Application for creating a service or software that is directly or indirectly, competitive with the Application or the Services; or (xii) derive any confidential information, processes, data or algorithms from the Application.
                <br />
                You also expressly agree not to engage in any use or activity that (a) may interrupt, destroy, alter, damage, delay, or limit the functionality or integrity of the Application or the Plans including that of, any associated software, hardware, telecommunications or wireless equipment; (b) may manipulate identifiers, or numeric information to disguise the origin of any user, device, material or other information; (c) may interfere with the proper working of the Application or prevent others from using the Application; or (d) may delete the copyright and other proprietary rights notices on the Application.
                <br />
                You agree not to use the Application for any activity relating to infringement of any intellectual property rights, including any trademarks, service marks, or trade names, privacy rights, personality rights, or any other proprietary rights of any third party. The Service is intended for users in India and users may access the same via the Application and/or the Website in the territory of India and Tinkerix shall not be liable for use and effect of such use outside India. Tinkerix shall have the right to use various technologies and digital rights management mechanisms to enforce this territorial restriction and/or to verify your compliance with this restriction.
                <br />
                Nothing contained herein shall be construed or implied to grant any right or license to use any trademarks, trade names, service marks or logos, which are a part of the Application or the Plans, without the prior written consent of the owner of rights in such marks.
                <br />
                You shall not use the Services by any means other than through use of the Application or Website. You shall, unless explicitly provided herein, neither directly nor through the use of any device, software, web-based service, or other means copy, download, capture, reproduce, duplicate, archive, distribute, upload, modify, sell the Plans or any part thereof.
                <br />
                You agree not to, or permit anyone else to, circumvent, disable or otherwise interfere with security-related features and any digital rights management mechanisms of the Application. You agree that Tinkerix shall have no liability for, and you agree to defend, indemnify and hold Tinkerix harmless against, any claims, losses or damages arising out of, or in connection with, your unauthorized use of the Application including any information made available via the Application.
                <br />
                <h3 id="ECaACAJ3SaC">Rights</h3>
                Upon your breach of this agreement or any other restrictions or guidelines of Tinkerix for use of the Application, Tinkerix reserves the right to take any responsive actions which Tinkerix may deem appropriate. Such actions may include (but may not be limited to) immediate suspension or cancellation of your access to the application. <br />
                <h3 id="ECaACAJ3SaC">Indemnity</h3>
                You shall indemnify and hold harmless, Tinkerix, its affiliates, any third party content / networks / infrastructure providers and their respective directors, officers, personnel, contractors and agents, for and against any and all claims, suits, judgment, losses, damages, cost and expenses arising or relating to your use of the Application, the Services and/or the Plans or your breach of the terms of the agreement or any other restrictions or guidelines provided by Tinkerix. This indemnification obligation will survive this agreement and your use of the Application/Services/Plans.
                <h3 id="ECaACAJ3SaC">Disclaimer of Warranties</h3>
                THE APPLICATION, SERVICES AND ALL CONTENT PROVIDED THROUGH THE APPLICATION, IS PROVIDED ON AN “AS-IS” AND “WITH ALL FAULTS AND RISKS” BASIS, WITHOUT WARRANTIES OF ANY KIND. DIGISSENTIAL DOES NOT WARRANT, EXPRESSLY OR BY IMPLICATION, THE ACCURACY OR RELIABILITY OF THE APPLICATION, SERVICES OR ANY CONTENT OR ITS SUSTAINABILITY FOR A PARTICULAR PURPOSE. FURTHER, THE SERVICES ARE PROVIDED TO YOU BASED ON VARIABLE FACTORS SUCH AS CROWD SOURCED INFORMATION AND INPUTS FROM YOU AND HENCE THE ACCURACY OF THE SERVICES CANNOT BE GUARANTEED BY DIGISSENTIAL. ACCORDINGLY, DIGISSENTIAL DOES NOT ACCEPT ANY RESPONSIBILITY FOR ACCURACY AND AVAILABILITY OF THE APPLICATION, THE SERVICES AND/OR CONTENT, AT ANY TIME. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAWS, DIGISSENTIAL DISCLAIMS ALL WARRANTIES WHETHER EXPRESS OR IMPLIED, INCLUDING THOSE OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE APPLICATION, SERVICES AND THE CONTENT OR ANY M ATERIAL THE REOF WILL BE UNINTERRUPTED OR ERROR-FREE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, DIGISSENTIAL DOES NOT REPRESENT OR WARRANT THAT THE APPLICATION WILL RESULT IN COMPLIANCE, FULFILLMENT OR CONFORMITY WITH THE LAWS, REGULATIONS, REQUIREMENTS OR GUIDELINES OF ANY GOVERNMENT OR GOVERNMENTAL AGENCY.<br />
                To the maximum extent permitted by applicable law, Tinkerix provides no warranty on use of the Plans and shall not be liable for the same under intellectual property rights, libel, privacy, publicity, obscenity or other laws. Tinkerix also disclaims all liability with respect to the misuse, loss, modification or unavailability of any Plans.<br />
                <h3 id="ECaACAJ3SaC">Limitation of Liability</h3>
                YOU ASSUME THE ENTIRE RISK OF USING THE APPLICATION, THE SERVICES AND THE PLANS. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL DIGISSENTIAL BE LIABLE TO YOU FOR ANY SPECIAL, INCIDENTAL, INDIRECT, PUNITIVE OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, FAULTY SELECTION OF CONTENT BY DIGISSENTIAL, FOR YOU, BASED ON THE SERVICES PROVIDED, LOSS OF INFORMATION, OR ANY OTHER PECUNIARY LOSS) ARISING OUT OF THE USE OF, OR INABILITY TO USE OR ACCESS OF THE APPLICATION, THE SERVICES OR THE CONTENT OR FOR ANY SECURITY BREACH OR ANY VIRUS, BUG, UNAUTHORIZED INTERVENTION, DEFECT, OR TECHNICAL MALFUNCTIONING OF THE APPLICATION, WHETHER OR NOT FORESEEABLE OR WHETHER OR NOT DIGISSENTIAL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR BASED ON ANY THEORY OF LIABILITY, INCLUDING BREACH OF CONTRACT OR WARRANTY, NEGLIGENCE OR OTHER TORTIOUS ACTION, OR ANY OT  HER CLAIM A RISING OUT, OF OR IN CONNECTION WITH, YOUR USE OF, OR ACCESS TO, THE APPLICATION, SERVICES OR THE CONTENT. FURTHER, DIGISSENTIAL SHALL NOT BE LIABLE TO YOU FOR ANY TEMPORARY DISABLEMENT, PERMANENT DISCONTINUANCE OR MODIFICATION OF THE APPLICATION BY DIGISSENTIAL OR FOR ANY CONSEQUENCES RESULTING FROM SUCH ACTIONS. <br /><br />
                TINKERIX’S AGGREGATE LIABILITY (WHETHER UNDER CONTRACT, TORT INCLUDING NEGLIGENCE, WARRANTY OR OTHERWISE) AND THAT OF ITS AFFILIATES SHALL BE LIMITED TO INR [Rs. 5,000].
                <h3 id="ECaACAJ3SaC">Governing Law</h3>
                This agreement is governed and construed in accordance with the laws of India. The courts in Mumbai shall have exclusive jurisdiction to hear disputes arising out of this agreement.<br />
                <h3 id="ECaACAJ3SaC">Force Majeure</h3>
                Tinkerix shall be under no liability whatsoever in the event of non-availability of any portion of the Application, Services or subscribed Plans occasioned by act of God, war, disease, revolution, riot, civil commotion, strike, lockout, flood, fire, failure of any public utility, man-made disaster, infrastructure failure or any other cause whatsoever beyond the control of Tinkerix.<br />
                <h3 id="ECaACAJ3SaC">Waiver</h3>
                Any failure by Tinkerix to enforce the agreement, for whatever reason, shall not necessarily be construed as a waiver of any right to do so at any time.<br />
                <h3 id="ECaACAJ3SaC">Severability</h3>
                If any of the provisions of this agreement are deemed invalid, void, or for any reason unenforceable, that part of the agreement will be deemed severable and will not affect the validity and enforceability of any remaining provisions of the agreement.<br />
                <h3 id="ECaACAJ3SaC">Entire Agreement</h3>
                The agreement as amended from time to time, constitutes the entire agreement between the parties and supersedes all prior understandings between the parties relating to the subject matter herein.<br />
                <h3 id="ECaACAJ3SaC">Payment</h3>
                All payments are processed through our payment partner PayTM. No information pertaining to payment is shared with us as you fill all the payment related information on our partner's site.
                <h3 id="ECaACAJ3SaC">Recharge</h3>
                Tinkerix is only a facilitator of prepaid mobile services by telecommunications service providers or other distributors or aggregators. Tinkerix is not a warrantor, insurer, or guarantor of the services to be provided by the telecommunications providers. Tinkerix has tied up with a third party enabler for the recharge. Tinkerix is not responsible for any delay, pricing or cancellation of recharge from mobile operators end. Though, best efforts are made to keep the service above acceptance levels.
                User is solely responsible for the selection of mobile operator and the recharge amount. Tinkerix is not responsible for talk-time given against recharge done through Cellfish as this is purely at mobile operator's disposal.
                <br />
                <br />
                Please contact us at <b>support@tinkerix.com</b> for any concerns, issues or questions.<br />
                <br />
                <i>We may change this policy from time to time, and if we do we’ll post any changes on this page. If you continue to use Cellfish after those changes are in effect, you agree to the revised policy. If the changes are significant, we may provide more prominent notice or get your consent as required by law.</i><br />
                <br />
                <i><b>Effective: July 22, 2015</b></i><br />
                <br />
                <br />
              </div>
              <div className="col-xs-1 col-md-2 col-sm-2" />
            </div>
            <div className="termsSeparator col-xs-12 col-sm-12 col-md-12">&nbsp;</div>.
          </div>
        );
      }
}

export default Tnc;