import React,{Component} from 'react';
import './appview1.css'
import bubbleInfo from './bubbleInfo'

export default function Bubble(props){
    const {getActiveBubble,name} = props;


    return(
        <div onClick={() => getActiveBubble(name)} className="featuresText text-center col-sm-3 col-lg-3 col-md-3 col-xs-3">
            <img id="usagePic" className="featurePic" height="60px" width="70px" alt="icon" src={props.image} style={{backgroundColor: props.color}}/>
            <br /><br />{props.title}
         </div>
    );
}
 