import React,{Component} from "react";

// import rechargeGif from './images/recharge-min.gif'
// import cutBillGif from './images/Cut-your-Mobile-bill-min.gif'
// import saveMoreGif from './images/Save-more-for-your-family-min.gif'
// import earnMoreGif from './images/Earn-more-through-referal-min.gif'
import './phonescreen.scss'
import phoneFrame from '../../../images/phonescreenfinal.png'

// var sass = require('node-sass');
// sass.renderSync({
//     file: './phonescreen.scss'
// });


class PhoneScreen extends Component{
    render(){
        return(
            <div className="_container _container-appscreens hidden-xs">
                <div className="container full-h">
                    <div className="row full-h">
                        <div className="appscreens--col col-sm-3 col-sm-offset-8 is-relative full-h">
                        <div className="appscreens-frame">
                            <div className="appscreens--phone">
                            {/* <div className="appscreens--phone-2nd-fold cellfish">
                                <ul className="ui-screen-wrapper ui-features">
                                <li className="ui-screen ui-feature" id="ui-feature-2"><img src="images/cellscreens/recommendation.jpeg" alt="prepaid phone plans" /></li>
                                <li className="ui-screen ui-feature" id="ui-feature-3"><img src="images/cellscreens/simmanagescreen.png" alt="save for your family" /></li>
                                <li className="ui-screen ui-feature" id="ui-feature-4"><img src="images/cellscreens/bill.jpeg" alt="prepaid phone bill" /></li>
                                <li id="ui-feature-1" className="ui-screen ui-feature"><img src="images/cellscreens/usagescreen.png" alt="Know your usage" /></li>
                                </ul>
                            </div> */}
                            <div className="appscreens--phone-1st-fold cellfish">
                                <ul className="ui-screen-wrapper ui-slider">
                                <li className="ui-screen ui-slide">
                                    {/* <img src={rechargeGif} alt="Self recharging robot" /> */}
                                </li>
                                <li className="ui-screen ui-slide">
                                    {/* <img src={cutBillGif} alt="Cut your mobile bills in half" /> */}
                                </li>
                                <li className="ui-screen ui-slide">
                                    {/* <img src={saveMoreGif} alt="Save Money for your family" /> */}
                                </li>
                                <li className="ui-screen ui-slide">
                                    {/* <img src={earnMoreGif} alt="Earn Every Month Through Referrals" /> */}
                                </li>
                                </ul>
                            </div>
                            <div className="appscreens--phoneFrame">
                                <div className="appscreens--phoneFrame__wrapper">
                                <img src={phoneFrame} alt="Smart Phone" />
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PhoneScreen;