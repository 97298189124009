import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import './index.css'

const styles = {
  card: {
    maxWidth: 500,
    height : 90,
    marginBottom: 10,
    marginLeft : 40,
    backgroundColor : '#FFFFFF',
  },
  media: {
    float: 'left',
    width: 200,
    height : 100,
    padding: 10,
  },

  content:{
    float: 'left',
    width : 200,
    height : 100,
    padding: 10,
  },

  data:{
    textAlign: 'center',
    padding: '25px',
    fontSize: '20px',
    color: '#673AB7',
  }
  
};

function MediaCard(props) {
  const { classes } = props;
  return (
    <Card className={classes.card}>
        <CardMedia
          className={classes.media}>
            <div>
              <i className ={props.classIcon} aria-hidden="true"></i>
              <h5 style={{marginLeft : 5, color: '#673AB7'}}>{props.tag}</h5>
            </div>
          </CardMedia>
        <CardContent className={classes.content}>      
          <Typography gutterBottom variant="h5" component="h2" className={classes.data}>
            {props.usage} <span>{props.unit}</span>
          </Typography>
        </CardContent>
    </Card>
  );
}

MediaCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MediaCard);
