import React,{Component} from 'react';

import { withFirebase } from '../Firebase';
import AppContent from '../appcontent/AppContent';

const SignOutButton = ({ firebase, history }) => (
  <button type="button" onClick={() => {
    history.push("/");
    firebase.doSignOut()
  }}>
    Sign Out
  </button>
);


class SignOut extends Component{
  constructor(props){
    super(props);
    this.state={
      firebase : null,
    }
  }
  componentWillMount(){
    this.state.firebase.doSignOut();
  }

  render(){
    return(
      <div></div>
    );
  }
}
export default withFirebase(SignOutButton);
 